import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import rootReducer from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import storage from "redux-persist/lib/storage";

const middleware = [thunk, logger]; // thunk for async actions
/**
 * PERSIST STORE
 */
const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    'user',
    'lastLocation',
    'product',
    'appElement',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
  persistedReducer,
  compose(composeWithDevTools(applyMiddleware(...middleware)))
);
let persistor = persistStore(store);

export {
    store,
    persistor,
};
