import {SET_GLOBAL_ERROR, CLEAR_GLOBAL_ERRORS} from "../types";

export function setGlobalErrors(errors) {
    return function (dispatch) {
        return dispatch({type: SET_GLOBAL_ERROR, payload: errors});
    }
}
export function clearGlobalErrors() {
    return function (dispatch) {
        return dispatch({type: CLEAR_GLOBAL_ERRORS});
    }
}
