import React from 'react';
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {Setting} from "@appucations/common-api";
import {useMutation} from "@apollo/react-hooks";
import CreateSettingComponent from "../../../components/setting/create";

function CreateSetting({ appElement, product, history }) {
    const [createSetting] = useMutation(Setting.createMutation());

    function handleCreate(data) {
        const { name, description, value, type, input_type } = data;
        createSetting({
            variables: {
                name,
                description,
                value: transformValue(value, input_type),
                type,
                input_type,
                product_id: product.id,
            }
        }).then(close);
    }

    function close() {
        history.push(`/settings`);
    }

    function transformValue(value, inputType) {
        if (inputType === 'checkbox') {
            return value ? 'true' : 'false';
        }

        return value;
    }

    return (
        <CreateSettingComponent
            appElement={appElement}
            history={history}
            handleCreate={handleCreate}
            handleEdit={close}
        />
    )
}

function withRedux({ appElement, product }) {
    return { appElement, product }
}

export default connect(withRedux, null)(withRouter(CreateSetting));
