import deComponents from "@appucations/core/dist/translation/languages/de";

export default {
    translation: {
        ...deComponents.translation,
        'headline': 'Überschrift',
        'dauer': 'Prüfungsdauer in Minuten',
        'richtige_antworten_anzeigen': 'Zeige die Anzahl der richtigen Antworten je Prüfungsfrage an',
        'mindestquote_zum_bestehen': 'Mindestquote der erreichbaren Punkte zum Bestehen der Prüfung in Prozent',
        'points': 'Punkte',
        'wysiwyg': 'Text',
        'openLearningArea': 'Thema öffnen',
        'learningAreaHeader': 'Thema {{count}} / {{countTotal}}',
        'learningContent': 'Bereich',
        'learningContents': 'Bereiche',
        'openLearningContent': 'Lernen',
        'addLearningContent': 'Lerninhalt hinzufügen',
        'learningSubArea': 'Kapitel',
        'learningSubAreas': 'Kapitel',
        'openSubArea': 'Bereich öffnen',
        'addSubArea': 'Lernbereich hinzufügen',
        'addLearningArea': 'Themenbereich hinzufügen',
        'examine': 'Prüfen',
        'search': 'Suchen',
        'enterQuestion': 'Frage eingeben ...',
        'uncategorized': 'Unkategorisiert',
        'answer': 'Antwort',
        'answers': 'Antworten',
        'learning': 'Lernen',
        'info': 'Info',
        'generalInstructions': 'Allgemeine Hinweise',
        'writtenExamInstructions': 'Hinweise zur schriftlichen Prüfung',
        'oralExamInstructions': 'Hinweise zur mündlichen Prüfung',
        'generalErrorTitle': 'Fehler',
        'generalErrorDescription': 'Es ist ein allgemeiner Fehler aufgetreten:',
        'countQuestion': '{{count}} Frage',
        'countQuestions': '{{count}} Fragen',
        'createSetting': 'Einstellung erstellen',
        'editSetting': 'Einstellung bearbeiten',
        'settings': 'Einstellungen',
        'name': 'Name',
        'value': 'Wert',
        'type': 'Typ',
        'typeExam': 'Prüfungseinstellung',
        'inputType': 'Eingabetyp',
        'inputTypeNumber': 'Zahl',
        'inputTypeCheckbox': 'Checkbox',
        'choose': 'Bitte auswählen',
        'yes': 'Ja',
        'no': 'Nein',
        'thanksPage': 'Danksagung',
        'partnerPage': 'Partner Seite',
        'sure?': 'Möchten Sie diesen Artikel löschen?',
        'order': 'Reihenfolge: {{count}}',
        'question_count': 'Fragen anzahl: {{count}}',
        'close': 'Abbrechen',
        'delete': 'Löschen',
    }
}
