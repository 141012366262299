import React, { useEffect, useState } from "react";
import FormWrapper from "@appucations/core/dist/components/organisms/formWrapper";
import LearningAreaCard from "@appucations/core/dist/components/molecules/learningAreaCard";
import { useTranslation } from "react-i18next";

import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export default function FilledLearningArea({
  appElement,
  learningArea,
  index,
  learningAreaTotal,
  handleUpdate,
  editableConfig,
  buttonText,
  handleDelete,
  goToSubAreas,
  hasErrors,
}) {
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const { t } = useTranslation();
  learningArea.learningAreaCount = index + 1;
  const header = t("learningAreaHeader", {
    count: learningArea.learningAreaCount,
    countTotal: learningAreaTotal,
  });
  return (
    <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Body>{t("sure?")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("close")}
          </Button>
          <Button variant="primary"onClick={() =>{ handleDelete(learningArea.id); setShowModal(false)}}>{t("delete")}</Button>
        </Modal.Footer>
      </Modal>
      <FormWrapper
        appElement={appElement}
        initialValues={learningArea}
        handleSubmit={handleUpdate}
        editable={editableConfig}
      >
        <LearningAreaCard
          hasErrors={hasErrors}
          headline={learningArea.headline}
          points={learningArea.points}
          buttonText={buttonText}
          showActionButtons
          showPoints
          save={handleUpdate}
          remove={() => {
            setShowModal(true);
          }}
          onClick={() => goToSubAreas(learningArea.id)}
          header={header}
        />
      </FormWrapper>
    </>
  );
}

