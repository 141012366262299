import React from 'react';
import {connect} from "react-redux";
import {useParams, withRouter} from "react-router";
import WrappedQuestionForm from "@appucations/core/dist/components/organisms/questionForm";
import {useMutation, useQuery} from "react-apollo";
import Question from "@appucations/common-api/dist/graphql/question";
import Banner from "@appucations/core/dist/components/molecules/banner";
import {useTranslation} from "react-i18next";

export function UpdateQuestion({appElement, history}) {
    const {t} = useTranslation();
    const {learning_area_id, questionId} = useParams();
    const {data: questionData, loading: questionIsLoading} = useQuery(Question.getQuery(), {variables: {id: questionId}});
    const [deleteQuestion] = useMutation(Question.deleteMutation());
    const [updateQuestion] = useMutation(Question.updateMutation());

    function close() {
        history.push(`/questions/${learning_area_id}`);
    }

    function save(data) {
        const {question: {id, question, points, answers, type, is_exercisable}} = data;
        let answersToSent = answers.filter(answer => answer.text);
        let answersInput = answersToSent.map(answer => ({
            is_correct: answer.is_correct,
            text: answer.text,
            order: answer.order,
            points: answer.points,
        }));

        try {
            updateQuestion({
                variables: {
                    id,
                    question,
                    points,
                    answers: answersInput,
                    type: parseInt(type),
                    is_exercisable,
                    learning_area_id,
                },
            }).then(close);
        } catch (e) {
        console.log(e)}
    }



    function remove(id) {
        deleteQuestion({variables: {id}})
            .then(close);
    }

    if (questionIsLoading) {
        return <div>Loading</div>
    }

    return (
        <Banner headline={ t('examine') } onClick={close}>
            <WrappedQuestionForm
                appElement={appElement}
                question={questionData.question}
                close={close}
                handleSubmit={save}
                remove={() => remove(questionData.question.id)}
            />
        </Banner>
    );
}

export default connect(({appElement}) => ({appElement}))(withRouter(UpdateQuestion));

