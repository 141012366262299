import styled from 'styled-components';

export const Wrapper = styled.div`
    display: grid;
    h3 {
        color: #738EA5;
        text-align: center;
    }
    form {
        padding: 10px 0;
    }
    
    table {
        border-collapse: collapse;
        width: 100%;

        td, table th {
            border: 1px solid #ced6dc;
            padding: 8px;
        }
        tr:nth-child(even){background-color: #fff;}
        tr:hover {background-color: #f3f3f3;}
        th {
            padding-top: 12px !important;
            padding-bottom: 12px !important;
            text-align: center !important;
            background-color: rgb(115,142,164) !important;
            color: white !important;
        }
    }
`;
