// react
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
// styles
import styled from 'styled-components'
import classNames from 'classnames'
import { colors } from '../../../variables/css'
import { Appucations, Profile } from '../../icons'
import ProductSelector from "../../../containers/ProductSelector";
import "bootstrap/dist/css/bootstrap.min.css";
import {Button} from "react-bootstrap";
import {connect} from "react-redux";
import {clearGlobalErrors} from "../../../redux/actions/globalError";
import {signOut} from "../../../redux/actions";
import {withRouter} from "react-router";
import {Page} from "../../../pages";

const Wrapper = styled.div`
	width: 100%;
	height: 60px;

	display: grid;
	grid-template-columns: 60px 1fr 60px;
	
	background: #f3f3f3;
	box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
	
	> * {
		justify-self: center;
		align-self: center;
	}
	
	> svg {
		width: 40px;
		fill: ${ colors.semi_dark_grey };
	}
	
	> span {
		font-size: 20px;
		color: ${ colors.dark_blue };
	}
	
	> 
/*	div {
		width: 40px;
		height: 40px;
		
		border-radius: 50%;
		
		background: ${ colors.blue };
	}*/
`;

function Component({ signOut, name, ...otherProps }) {
	const [loginButton, setLoginButton] = useState(false)
	return (
		<>

			<Wrapper { ...otherProps }>
				<Appucations />
				<span><ProductSelector /></span>
					<Profile
						variant="success" id="dropdown-basic"
						id={'profile'}
						onMouseEnter={() => {
							console.log("Event:MouseEnter");
								setLoginButton(true);


						}}
						onMouseLeave={() => {
							console.log("Event:MouseLeave");
							setTimeout(() => {
								setLoginButton(false);
							},[4000])
						}}
						/>
			</Wrapper>
			{loginButton &&
			<Button variant="secondary" onClick={() => signOut()} style={{position: 'absolute', top: '60px', right:'20px', background: 'rgb(115,142,164)' }}>
				LogOut
			</Button>}
		</>
	)
}

Component.propTypes = {};

Component.defaultProps = {
	name: "Projekt/App Name"
};
function withRedux({appElement, globalError}) {
	return {appElement, errors: globalError.errors}
}
export default connect(withRedux, {signOut})(withRouter(Component));

