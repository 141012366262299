import React, { useState } from "react";
import { Wrapper } from "../../infoArea/styled";
import Actions from "@appucations/core/dist/components/molecules/action";
import Banner from "@appucations/core/dist/components/molecules/banner";
import List from "@appucations/core/dist/components/organisms/list";
import FormWrapper from "@appucations/core/dist/components/organisms/formWrapper";
import Wysiwyg from "@appucations/core/dist/components/atoms/wysiwyg";
import { useTranslation } from "react-i18next";
import getDefaultWysiwygConfig from "../../../helpers/wysiwyg";
import Input from "@appucations/core/dist/components/atoms/input";
import './learning-content.css';



export default function CreateLearningContent({
  appElement,
  history,
  subAreaId,
  handleCreate,
  handleEdit,
}) {
  const { t } = useTranslation();
  const editableConfig = {
    headline: {
      name: "headline",
      labelText: t("headline"),
    },
    sub_area_id: {
      name: "sub_area_id",
      labelText: "sub_area_id",
    },
    wysiwyg: {
      name: "wysiwyg",
      labelText: t("wysiwyg"),
    },
    reading_time: {
      name: "reading_time",
      labelText: "Lesezeit in Min.",
    },
  };

  return (
    <Banner headline={t("learningContent")} onClick={() => history.goBack()}>
      <List>
        <Wrapper>
          <FormWrapper
            appElement={appElement}
            initialValues={{
              sub_area_id: subAreaId,
            }}
            handleSubmit={handleCreate}
            editable={editableConfig}
          >
            <CreateForm handleSubmit={handleCreate} handleEdit={handleEdit} />
          </FormWrapper>
        </Wrapper>
      </List>
    </Banner>
  );
}

function CreateForm({ editable, values, handleSubmit, handleEdit }) {
  const [headline, setHeadline] = useState(values.headline);
  const [wysiwyg, setWysiwyg] = useState(values.wysiwyg);
  const [reading_time, setReadingTime] = useState(values.reading_time);

  const wysiwygConfiguration = getDefaultWysiwygConfig();
    console.log('create values', values)
  return (
    <div>
      <Actions
        isEditMode={true}
        edit={handleEdit}
        save={() => handleSubmit(values)}
      />
      <input
        type="hidden"
        value={values.sub_area_id}
        onChange={editable.onChange}
        {...editable.sub_area_id}
      />
      <input
        type="hidden"
        value={(values.headline = headline)}
        onChange={editable.onChange}
        {...editable.headline}
      />
      <input
        type="hidden"
        value={(values.wysiwyg = wysiwyg)}
        onChange={editable.onChange}
        {...editable.wysiwyg}
      />
        <input
        type="hidden"
        value={(values.reading_time = reading_time)}
        onChange={editable.onChange}
        {...editable.reading_time}
      />
      <Wysiwyg
        data={values.headline}
        config={wysiwygConfiguration}
        onChange={(event, editor) => {
          const data = editor.getData();
          setHeadline(data);
        }}
      />
      <hr />
      <Wysiwyg
        data={values.wysiwyg}
        config={wysiwygConfiguration}
        onChange={(event, editor) => {
          const data = editor.getData();
          setWysiwyg(data);
        }}
      />
      <Input
        value={values.reading_time}
        type="number"
        step="0.1"
        onChange={(e) => setReadingTime(e.target.value)}
        {...editable.reading_time}
      />
    </div>
  );
}
