// react
import React from 'react';
// hoc
// styles
import styled from 'styled-components'
// components
import Headline from './headline';
import Nav from './nav';
import Routes from "../../../routes/dashboard";
import isInsideIframe from "../../../helpers/window";

const Wrapper = styled.div`
	width: 100%;
	height: 100%;

	display: grid;
	
	grid-template-rows: 60px 1fr;
	grid-template-columns: 250px 1fr;
	
	> .headline {
		grid-row: 1 / 2;
		grid-column: 1 / 3;
	
		z-index: 1;
	}
	
	> .nav {
		grid-row: 2 / 3;
		grid-column: 1 / 2;
	}
`;
const WrapperIframe = styled(Wrapper)`
	grid-template-rows: none;
	grid-template-columns: none;
`;

const Content = styled.div`
	background: #f3f3f3;
	padding: 20px;
`;

export default function Dashboard({ ...otherProps }) {
	return !isInsideIframe() ? (
		<Wrapper { ...otherProps }>
			<Headline className="headline" />
			<Nav />
			<Content>
				<Routes />
			</Content>
		</Wrapper>
	): (
		<WrapperIframe { ...otherProps }>
			<Content>
				<Routes />
			</Content>
		</WrapperIframe>
	)
}

