import { createGlobalStyle } from 'styled-components'
import { normalize } from 'polished'

export default createGlobalStyle`
	${ normalize()};
	html, body, div#root {
		width: 100%;
		height: 100%;
		
		padding: 0;
		margin: 0;
	}
	
	* {
		font-family: 'Montserrat', sans-serif;
		font-weight: 400;
		text-decoration: none;
		color: inherit;
	}

	.group { 
		position:relative; 
		margin-bottom:10px;
		input[type="checkbox"] {
			position: relative;
			top: 15px;
		}
	  }
	  input 				{
		font-size:18px;
		padding:10px 10px 10px 5px;
		display:block;
		width:100%;
		border:none;
		background-color: transparent;
	  }
	  input:focus { outline:none; }
	  
	  label {
		color:#999; 
		font-size:18px;
		font-weight:normal;
		position:absolute;
		pointer-events:none;
		left:5px;
		top:10px;
		transition:0.2s ease all; 
		-moz-transition:0.2s ease all; 
		-webkit-transition:0.2s ease all;
	  }
	  input:focus ~ label, input:not(:placeholder-shown) ~ label {
		top: -5px;
		font-size:14px;
		color:#5264AE;
	  }
	  .bar 	{ position:relative; display:block; width:100%; }
	  .bar:before, .bar:after 	{
		content:'';
		height:2px; 
		width:0;
		bottom:1px; 
		position:absolute;
		background:#5264AE; 
		transition:0.2s ease all; 
		-moz-transition:0.2s ease all; 
		-webkit-transition:0.2s ease all;
	  }
	  .bar:before {
		left:50%;
	  }
	  .bar:after {
		right:50%; 
	  }
	  
	  input:focus ~ .bar:before, input:focus ~ .bar:after {
		width:50%;
	  }
	  
	  .highlight {
		position:absolute;
		height:60%; 
		width:100px; 
		top:25%; 
		left:0;
		pointer-events:none;
		opacity:0.5;
	  }
	  
	  input:focus ~ .highlight {
		-webkit-animation:inputHighlighter 0.3s ease;
		-moz-animation:inputHighlighter 0.3s ease;
		animation:inputHighlighter 0.3s ease;
	  }
	  
	  @-webkit-keyframes inputHighlighter {
		  from { background:#5264AE; }
		to 	{ width:0; background:transparent; }
	  }
	  @-moz-keyframes inputHighlighter {
		  from { background:#5264AE; }
		to 	{ width:0; background:transparent; }
	  }
	  @keyframes inputHighlighter {
		from { background:#5264AE; }
		to 	{ width:0; background:transparent; }
	  }
`;
