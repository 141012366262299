import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { useMutation, useQuery } from "react-apollo";
import InfoArea from "../../components/infoArea";
import Banner from "@appucations/core/dist/components/molecules/banner";
import List from "@appucations/core/dist/components/organisms/list";
import { Product } from "@appucations/common-api";
import { useTranslation } from "react-i18next";
import { date } from "@storybook/addon-knobs";

export function InfoPage({ history, product, lastLocation, appElement }) {
  const { t } = useTranslation();
  const { data, refetch } = useQuery(Product.getQuery(), {
    variables: { id: product.id },
  });
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [updateProductInstructions] = useMutation(
    Product.updateInstructionsMutation()
  );

  useEffect(() => {
    selectProduct();
  }, [product, data]);

  function selectProduct() {
    if (product.id) {
      const foundProduct = data && data.product;
      if (foundProduct) {
        setSelectedProduct(foundProduct);
      } else {
        setSelectedProduct(null);
      }
    }
  }

  function handleSubmit(data) {
    const {
      id,
      general_instructions,
      written_exam_instructions,
      oral_exam_instructions,
      thanks_page,
      partner_page,
    } = data;
    updateProductInstructions({
      variables: {
        id,
        general_instructions,
        written_exam_instructions,
        oral_exam_instructions,
        thanks_page,
        partner_page,
      },
    })
      .then((response) => {
        refetch();
      })
      .catch((e) => console.log("eer => ", e));
  }

  return (
    <Banner headline={t("info")} onClick={() => history.goBack()}>
      <List>
        <InfoArea
          lastLocation={lastLocation}
          handleSubmit={handleSubmit}
          product={selectedProduct}
          appElement={appElement}
        />
      </List>
    </Banner>
  );
}

function withRedux({ history, product, lastLocation, appElement }) {
  return { history, product, lastLocation, appElement };
}

export default connect(withRedux, null)(withRouter(InfoPage));
