import {Route, Switch} from "react-router-dom";
import Login from "../pages/authentication/login";
import Dashboard from "../components/appucationsComponents/dashboard";
import React, {useEffect} from "react";
import {connect} from "react-redux";
import {withRouter, useHistory, useLocation} from "react-router";
import {setLastLocation} from "../redux/actions/lastLocation";
import Page from "../pages";
import ResetPassword from "../pages/authentication/setEmailForPasswordReset";
import Learning from "../pages/learning";

export function Routes({user, lastLocation, setLastLocation}) {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname !== '/login' && location.pathname !== '/password-reset' ) {
            setLastLocation(location);
        }
    }, [location]);

    useEffect(() => {
        if (user.authenticationToken) {
            history.push(lastLocation.pathname);
        } else {
            history.push('/login');
        }
    }, [user]);

    return (
        <Page>
            <Switch>
                <Route exact path="/password-reset" component={ResetPassword}/>
                <Route exact path="/login" component={Login}/>
                <Route exact component={Dashboard}/>
            </Switch>
        </Page>
    );
}

export default connect(({user, lastLocation}) => ({user, lastLocation}), {setLastLocation})(withRouter(Routes));
