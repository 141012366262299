import React, { useState } from "react";
import Actions from "@appucations/core/dist/components/molecules/action";
import Banner from "@appucations/core/dist/components/molecules/banner";
import FormWrapper from "@appucations/core/dist/components/organisms/formWrapper";
import { useTranslation } from "react-i18next";
import Wysiwyg from "@appucations/core/dist/components/atoms/wysiwyg";
import getDefaultWysiwygConfig from "../../../../helpers/wysiwyg";
import Input from "@appucations/core/dist/components/atoms/input";

export default function CreateSubAreaItem({
  appElement,
  history,
  handleCreate,
  handleEdit,
}) {
  const { t } = useTranslation();
  const editableConfig = {
    headline: {
      name: "headline",
      labelText: t("headline"),
    },
    order: {
      name: "order",
      labelText: t("order"),
    },
  };

  return (
    <Banner headline={t("learningSubArea")} onClick={() => history.goBack()}>
      <FormWrapper
        appElement={appElement}
        initialValues={{}}
        handleSubmit={handleCreate}
        editable={editableConfig}
      >
        <CreateForm handleCreate={handleCreate} handleEdit={handleEdit} />
      </FormWrapper>
    </Banner>
  );
}

function CreateForm({ editable, values, handleCreate, handleEdit }) {
  const [headline, setHeadline] = useState(values.headline);
  const [order, setOrder] = useState(values.order);
  console.log(JSON.stringify(values), order);

  const wysiwygConfiguration = getDefaultWysiwygConfig();

  return (
    <div>
      <Actions
        isEditMode={true}
        edit={handleEdit}
        save={() => handleCreate({ headline, order })}
      />
      <input
        type="hidden"
        value={(values.headline = headline)}
        onChange={editable.onChange}
        {...editable.headline}
      />
      <Wysiwyg
        data={values.headline}
        config={wysiwygConfiguration}
        onChange={(event, editor) => {
          const data = editor.getData();
          setHeadline(data);
        }}
      />
      <Input
        value={order}
        type="text"
        onChange={(e) => setOrder(e.target.value)}
        {...editable.order}
      />
    </div>
  );
}
