import React from 'react';
import Banner from "@appucations/core/dist/components/molecules/banner";
import {Headline, SubHeadline} from "../../../panel/styled";
import SubAreaItem from "./index";
import AddSubAreaButton from "../../../buttons/round/addButton/subArea";
import AddLearningContentButton from "../../../buttons/round/addButton/learningContent";
import List from "@appucations/core/dist/components/organisms/list";
import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/react-hooks";
import LearningArea from "@appucations/common-api/dist/graphql/learningArea";

export default function SubAreaList({ history, learningAreaId, subArea, subAreas, linkToAddSubArea, linkToAddLearningContent }) {
    const {t} = useTranslation();
    const {data, loading} = useQuery(LearningArea.getQuery(), {variables: {id: learningAreaId}});

    if (loading) {
        return (
            <p>Loading ...</p>
        );
    }

    function countLearningContents(subAreas) {
        let count = 0;
        if (!subAreas) return 0;
        subAreas.forEach(subarea => {
            if (subarea.learning_contents) {
                count += subarea.learning_contents.length;
            }
        });
        return count;
    }

    return (
        <Banner headline={ t('learningContent') } onClick={() => history.goBack()}>
            <Headline>
                <h1>
                    <div dangerouslySetInnerHTML={{__html: subArea ? subArea.headline : data.learningArea.headline}}/>
                </h1>
                <SubHeadline>{`${countLearningContents(subAreas)} ` + t('learningContents') + ` - ${subAreas && subAreas.length || 0} ` + t('learningSubAreas')}</SubHeadline>
            </Headline>
            <List>
                {subAreas && subAreas.map(subArea => (
                    <SubAreaItem history={history} subarea={subArea} />
                ))}
            </List>
            <AddSubAreaButton onClick={linkToAddSubArea} title={ t('addSubArea') } />
            {subArea && (<AddLearningContentButton className="secondary" onClick={linkToAddLearningContent} title={ t('addLearningContent') } />)}
        </Banner>
    )
}
