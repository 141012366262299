import {SET_LAST_LOCATION} from "../types";

const initialState = {
  pathname: '/learning',
};

export default function lastLocation (state = initialState, action) {
    switch(action.type) {
        case SET_LAST_LOCATION:
            const newPathname = action.location.pathname;

            return {
                ...state,
                pathname: newPathname === '/login' ? state.pathname : newPathname,
            };
        default:
            return state;
    }
}
