import React, {useEffect} from 'react';
import {useQuery} from "@apollo/react-hooks";
import SelectField from "../../components/dataEntry/select";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {selectProduct} from "../../redux/actions/product";
import {Product} from "@appucations/common-api";

function ProductSelector({ product, history, selectProduct }) {
    const { loading, error, data, refetch } = useQuery(Product.getAllQuery());

    useEffect(() => {
        if (product.id) {
            return;
        }

        const select = document.querySelector('#ProductSelector');
        if (!select || select.length < 1) {
            return;
        }

        applyProductSelection(select.options[0].value);
    });

    function onChangeHandler(e) {
        applyProductSelection(document.querySelector('#ProductSelector').value);
    }

    function applyProductSelection(productId) {
        const previousProductId = product.id;
        const nextProductId = parseInt(productId);

        selectProduct(nextProductId);

        if (previousProductId !== nextProductId) {
            history.push('/learning');
        }
    }

    if (loading) return <p>Loading ...</p>;
    if (error) return <p>There was an error ...</p>;

    return (
            <SelectField
                id="ProductSelector"
                onChange={onChangeHandler}
                list={data.products.map(product => {
                     return  {
                        name: product.name + ` (#${product.id})  `,
                        id: product.id
                    }
                })}
                value={product.id ? product.id : null}
                name="product-selection"
            />
    )
}

function withRedux({ product, history }) {
    return { product, history }
}

export default connect(withRedux, { selectProduct })(withRouter(ProductSelector));
