import React from 'react';
import {Button} from "./styled";
import Plus from '../../../icons/plus';

export default function AddButton({ onClick, className, ...otherProps }) {
    return (
        <Button className={className} onClick={onClick} {...otherProps}><Plus /></Button>
    )
}


