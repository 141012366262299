import { combineReducers } from 'redux';
import user from './user';
import product from './product';
import lastLocation from './lastLocation';
import appElement from "./appElement";
import globalError from "./globalError";

export default combineReducers({
	user,
	product,
	lastLocation,
	appElement,
	globalError,
});
