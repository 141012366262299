import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {useParams, withRouter} from "react-router";
import {useQuery} from "@apollo/react-hooks";
import AddButton from "../../../../components/buttons/round/addButton";
import Banner from "@appucations/core/dist/components/molecules/banner";
import List from "@appucations/core/dist/components/organisms/list";
import Input from "@appucations/core/dist/components/atoms/input";
import LearningArea from "@appucations/common-api/dist/graphql/learningArea";
import Product from "@appucations/common-api/dist/graphql/product";
import ListItem from "@appucations/core/dist/components/molecules/listItem";
import Actions from "@appucations/core/dist/components/molecules/action";
import {ListItemWrapper} from "../../../../components/list/styled";
import {useTranslation} from "react-i18next";

export function QuestionList({history, product}) {
    const {t} = useTranslation();
    const {learningAreaId} = useParams();
    const [search, setSearch] = useState('');
    const {data, loading, refetch} = useQuery(LearningArea.getQuery(), {variables: {id: learningAreaId}});
    const {loading: productIsLoading} = useQuery(Product.getQuery(), {variables: {id: product.id}})
    const [filteredQuestions, setFilteredQuestions] = useState([]);

    useEffect(() => {
        refetch();
    });

    useEffect(() => {
        if (!loading) {
            filterQuestions(search);
        }
        if (!loading && data && data.learningArea) {
            filterQuestions(search);
        }
    }, [loading, data])

    if (loading || !data || !data.learningArea || productIsLoading) {
        return <div>loading</div>
    }

    function filterQuestions(term) {
        setFilteredQuestions(data.learningArea.questions.filter(question => question.question.toLowerCase().indexOf(term.toLowerCase()) > -1).reverse());
        setSearch(term);
    }

    function goToCreateQuestion() {
        history.push(`/question/create/${learningAreaId}`);
    }

    function goToEditQuestion(id) {
        history.push(`/question/${learningAreaId}/${id}/edit`)
    }

    function getAnswersChildCount(question) {
        const isAnswersSet = question && question.answers && question.answers.length;

        return isAnswersSet ? question.answers.length : 0;
    }

    function getChildCountLabel(question) {
        const answersChildCount = getAnswersChildCount(question);

        return answersChildCount + ' ' + (answersChildCount === 1 ? t('answer') : t('answers'));
    }

    return (
        <Banner headline={t('examine')} onClick={() => {
            history.push('/exams')
        }}>
            <Input value={search} labelText={t('search')} onChange={(e) => filterQuestions(e.target.value)}/>
                <List>
                    {filteredQuestions.map(question => (
                        <ListItemWrapper key={`question-${question.id}`}>
                            <Actions isEditMode={false} edit={() => goToEditQuestion(question.id)}/>
                            <ListItem
                                title={question.question || t('enterQuestion')}
                                subTitle={getChildCountLabel(question)}
                                removeButton
                            />
                        </ListItemWrapper>
                    ))}
                </List>

            <AddButton onClick={goToCreateQuestion}/>
        </Banner>
    );
}

export default connect(({product}) => ({product}))(withRouter(QuestionList));
