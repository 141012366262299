import {Route, Switch} from "react-router-dom";
import Learning from "../pages/learning";
import CreateSubArea from "../pages/learning/subarea/create";
import SubAreaList from "../pages/learning/subarea/list";
import UpdateSubArea from "../pages/learning/subarea/update";
import CreateLearningContent from "../pages/learning/subarea/learningContent/create";
import LearningContentList from "../pages/learning/subarea/learningContent/list";
import UpdateLearningContent from "../pages/learning/subarea/learningContent/update";
import Examing from "../pages/examing";
import InfoPage from "../pages/info";
import React from "react";
import CreateQuestion from "../pages/examing/question/create";
import QuestionList from "../pages/examing/question/list";
import UpdateQuestion from "../pages/examing/question/update";
import SettingsList from "../pages/settings/list";
import CreateSetting from "../pages/settings/create";
import UpdateSetting from "../pages/settings/update";
import ResetPassword from "../pages/authentication/setEmailForPasswordReset";

export default function Routes() {
    return (
        <Switch>
            <Route exact path="/learning" render={() => (<Learning/>)}/>
            <Route exact path="/learning/:learningAreaId" render={() => (<SubAreaList/>)}/>
            <Route exact path="/learning/create/:learningAreaId" render={() => (<CreateSubArea/>)}/>
            <Route exact path="/learning/create/:learningAreaId/:subAreaId" render={() => (<CreateSubArea/>)}/>
            <Route exact path="/learning/:learningAreaId/:subAreaId" render={() => (<SubAreaList/>)}/>
            <Route exact path="/learning/:learningAreaId/:subAreaId/edit" render={() => (<UpdateSubArea/>)}/>
            <Route exact path="/question/create/:learning_area_id" render={() => ( <CreateQuestion /> )}/>
            <Route exact path="/questions/:learningAreaId" render={() => ( <QuestionList /> )}/>
            <Route exact path="/question/:learning_area_id/:questionId/edit" render={() => ( <UpdateQuestion /> )}/>
            <Route exact path="/learning-content/create/:learningAreaId/:subAreaId" render={() => (<CreateLearningContent />)}/>
            <Route exact path="/learning-content/:learningAreaId/:subAreaId" render={() => (<LearningContentList />)}/>
            <Route exact path="/learning-content/:learningAreaId/:subAreaId/edit" render={() => (<UpdateLearningContent />)}/>
            <Route exact path="/flash-cards" render={() => (<div>Flash Cards</div>)}/>
            <Route exact path="/exams" render={() => (<Examing/>)}/>
            <Route exact path="/info" render={() => (<InfoPage/>)}/>
            <Route exact path="/settings" render={() => (<SettingsList/>)}/>
            <Route exact path="/settings/create" render={() => (<CreateSetting/>)}/>
            <Route exact path="/settings/:settingId/edit" render={() => (<UpdateSetting/>)}/>
        </Switch>
    );
}
