// todo: icon left and right
// react
import React from 'react'
import PropTypes from 'prop-types'
// styles
import styled from 'styled-components'
import classNames from 'classnames'
import { colors, sizes } from '../../variables/css'

const { buttons, fonts, icons, lineHeights } = sizes;

const Wrapper = styled.button`
    min-width: min-content;
    min-height: 50px;
    
    box-sizing: border-box;
    padding: 0 50px;
    border: none;
    border-radius: ${ buttons["default"] };
    
    display: grid;
    // grid-template-columns: repeat(3, min-content);
    grid-column-gap: 10px;
    
    white-space: nowrap;
    max-width: 250px;
    background: transparent;
    align-content: center;
    text-align: center;
    
    &.size-small {
        display: grid;
        width: 49px;
        height: 20px;
        padding: 0;
        border-radius: 0;
        min-height: 25px;
        svg {
            width: 15px;
        }
    }
    
    svg {
        margin: 0;
        min-width: ${fonts['default']};
    }
    
    &.is-block {
        width: 100%;
    }
    
    background: ${({ color }) => color};
    
    :hover {
        cursor: pointer;
    } 
`;

const Name = styled.span`
    color: ${ colors.font };
    font-size: ${ fonts["default"] };
    letter-spacing: 3px;
`;

function Component({ isBlock, size, color, IconLeft, IconRight, children: name, ...otherProps }) {
    return (
        <Wrapper { ...otherProps } className={ classNames({ "is-block": isBlock }, `size-${ size }`)} color={ color }>
            { !!IconLeft && ( <IconLeft width={ icons[size] } fill={ colors.font } /> ) }
            <Name className={`size-${ size }`}>{ name && name.hasOwnProperty() ? name.toUpperCase() : name }</Name>
            { !!IconRight && ( <IconRight width={ icons[size] } fill={ colors.font } /> ) }
        </Wrapper>
    )
}

Component.propTypes = {
    size: PropTypes.oneOf(["large", "default", "small"]),
    color: PropTypes.string,
};

Component.defaultProps = {
    size: "default",
    color: colors.blue
};

export default Component;
export {
    Wrapper,
};
