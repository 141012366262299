import React, { useState } from "react";
import { Wrapper } from "../../infoArea/styled";
import Actions from "@appucations/core/dist/components/molecules/action";
import Banner from "@appucations/core/dist/components/molecules/banner";
import List from "@appucations/core/dist/components/organisms/list";
import FormWrapper from "@appucations/core/dist/components/organisms/formWrapper";
import Wysiwyg from "@appucations/core/dist/components/atoms/wysiwyg";
import { useTranslation } from "react-i18next";
import getDefaultWysiwygConfig from "../../../helpers/wysiwyg";
import Input from "@appucations/core/dist/components/atoms/input";
import './learning-content.css';

export default function UpdateLearningContent({
  appElement,
  history,
  id,
  data,
  handleEdit,
  handleUpdate,
  handleDelete,
}) {
  const { t } = useTranslation();
  const editableConfig = {
    id: {
      name: "id",
      labelText: "id",
    },
    headline: {
      name: "headline",
      labelText: t("headline"),
    },
    sub_area_id: {
      name: "sub_area_id",
      labelText: "sub_area_id",
    },
    wysiwyg: {
      name: "wysiwyg",
      labelText: t("wysiwyg"),
    },
    reading_time: {
      name: "reading_time",
      labelText: "Lesezeit in Min.",
    },
    wysiwygConfig: getDefaultWysiwygConfig(),
  };

  return (
    <Banner headline="Lernbereich" onClick={() => history.goBack()}>
      <List>
        <Wrapper>
          {data &&
            data.learningContents &&
            data.learningContents.map((learningContent) => (
              <FormWrapper
                appElement={appElement}
                initialValues={{
                  ...learningContent,
                  sub_area_id: learningContent.subArea.id,
                }}
                handleSubmit={handleUpdate}
                handleDelete={handleDelete}
                editable={editableConfig}
                id={id}
              >
                <UpdateForm
                  handleEdit={handleEdit}
                  handleSubmit={handleUpdate}
                  handleDelete={handleDelete}
                />
              </FormWrapper>
            ))}
        </Wrapper>
      </List>
    </Banner>
  );
}

function UpdateForm({
  editable,
  values,
  handleEdit,
  handleSubmit,
  handleDelete,
  id,
}) {
  const [headline, setHeadline] = useState(values.headline);
  const [wysiwyg, setWysiwyg] = useState(values.wysiwyg);
  const [reading_time, setReadingTime] = useState((values.reading_time / 60).toFixed(1));

  const wysiwygConfiguration = getDefaultWysiwygConfig();

  return (
    <div>
      <Actions
        isEditMode={true}
        edit={handleEdit}
        save={() => handleSubmit(values)}
        id={id}
        remove={handleDelete}
      />
      <input
        type="hidden"
        value={values.id}
        onChange={editable.onChange}
        {...editable.id}
      />
      <input
        type="hidden"
        value={values.sub_area_id}
        onChange={editable.onChange}
        {...editable.sub_area_id}
      />
      <input
        type="hidden"
        value={(values.headline = headline)}
        onChange={editable.onChange}
        {...editable.headline}
      />
      <input
        type="hidden"
        value={(values.wysiwyg = wysiwyg)}
        onChange={editable.onChange}
        {...editable.wysiwyg}
      />
      <input
        type="hidden"
        value={(values.reading_time = reading_time)}
        onChange={editable.onChange}
        {...editable.reading_time}
      />
      <Wysiwyg
        data={values.headline}
        config={wysiwygConfiguration}
        onChange={(event, editor) => {
          const data = editor.getData();
          setHeadline(data);
        }}
      />
      <hr />
      <Wysiwyg
        data={values.wysiwyg}
        config={wysiwygConfiguration}
        onChange={(event, editor) => {
          const data = editor.getData();
          setWysiwyg(data);
        }}
      />
      <Input
        value={reading_time}
        type="number"
        onChange={(e) => setReadingTime(e.target.value)}
        {...editable.reading_time}
      />
    </div>
  );
}
