import React, {useEffect} from 'react';
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {useQuery} from 'react-apollo';
import {Setting} from "@appucations/common-api";
import SettingsListComponent from "../../../components/setting/list";

function SettingsList({ product, history }) {
    const {loading, data, refetch} = useQuery(Setting.getAllByProductIdQuery(), {variables: {product_id: product.id}})

    useEffect(() => {
        refetch();
    });

    if (loading) {
        return (
            <p>Loading ...</p>
        );
    }

    return (
        <SettingsListComponent
            settings={data.settings}
            history={history}
        />
    );
}

function withRedux({ product }) {
    return { product }
}

export default connect(withRedux, null)(withRouter(SettingsList));
