import {SELECT_PRODUCT} from "../types";

const initialState = {
  id: null,
};

export default function product (state = initialState, action) {
    switch(action.type) {
        case SELECT_PRODUCT:
            return {
                ...state,
                id: parseInt(action.productId, 10),
            };
        default:
            return state;
    }
}
