import { SIGN_IN, SIGN_OUT } from '../types';


export function signIn({ user, token }) {
	return function (dispatch) {
		return dispatch({ type: SIGN_IN, user, token })
	}
}

export function signOut() {
	return function (dispatch) {
		return dispatch({ type: SIGN_OUT })
	}
}