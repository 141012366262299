import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { useMutation, useQuery } from "@apollo/react-hooks";
import LearningAreaCard from "@appucations/core/dist/components/molecules/learningAreaCard";
import Grid from "@appucations/core/dist/components/organisms/grid";
import FormWrapper from "@appucations/core/dist/components/organisms/formWrapper";
import { LearningArea, Product } from "@appucations/common-api";
import AddButton from "../../components/buttons/round/addButton";
import { getEmptyCard } from "./data";
import EmptyLearningCardList from "../../components/learningArea/emptyLearningAreas";
import FilledLearningArea from "../../components/learningArea/filledLearningArea";
import { useTranslation } from "react-i18next";
import getDefaultWysiwygConfig from "../../helpers/wysiwyg";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.scss";
import error from "@appucations/core/dist/components/molecules/modal/error";

export function Learning({ appElement, history, product }) {
  const { t } = useTranslation();
  const [emptyCards, setEmptyCards] = useState([]);
  const [areas, setAreas] = useState([]);
  const [errors, setErrors] = useState({});

  const { loading: productLoading, refetch: refetchProduct } = useQuery(
    Product.getQuery(),
    {
      variables: { id: product.id },
      skip: !product.id,
    }
  );

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        console.log('Enter Pressed');
        document.querySelector(".learning-area-card .round-button--is-success").click()
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  const { loading, data, refetch } = useQuery(
    LearningArea.getAllByProductIdQuery(),
    {
      variables: { product_id: product.id, requester: "autorentool" },
    }
  );
  const [createLearningArea] = useMutation(LearningArea.createMutation());
  const [updateLearningArea] = useMutation(LearningArea.updateMutation());
  const [deleteLearningArea] = useMutation(LearningArea.deleteMutation());
  const editableConfig = {
    headline: {
      name: "headline",
      labelText: t("headline"),
    },
    points: {
      name: "points",
      labelText: t("points"),
    },
    order: {
      name: "order",
      labelText: t("order"),
    },
    question_count: {
      name: "question_count",
      labelText: t("question_count"),
    },
    wysiwygConfig: getDefaultWysiwygConfig(),
  };

  useEffect(() => {
    if (product.id) {
      setEmptyCards([]);
      refetchProduct();
    }
  }, [product, refetchProduct]);

  if (productLoading || loading) return <p>Loading ...</p>;

  function handleAdd(data) {
    const { headline, points, order, question_count } = data;
    let errorList = {};

    if (!points || isNaN(parseInt(points))) {
      errorList["points"] = { message: "Feld darf nicht leer sein" };
    }

    if (!order || isNaN(parseInt(order))) {
      errorList["order"] = { message: "Feld darf nicht leer sein" };
    }

    if (errorList["order"] || errorList["points"]) {
      setErrors(errorList);
      return;
    }

    const { id: product_id } = product;
    createLearningArea({
      variables: { product_id, headline, points, order, question_count },
    }).then(() => {
      refetchProduct();
      refetch();
      setErrors({});
      errorList = {};
    });

    handleDeleteEmptyCard(product_id);
    setEmptyCards([]);
  }

  function handleUpdate(data, closeForm) {
    const { headline, id, points, order, question_count } = data;
    let errorList = {};

    if (!points || isNaN(parseInt(points))) {
      errorList["points"] = { message: "Feld darf nicht leer sein" };
    }

    if (!order || isNaN(parseInt(order))) {
      errorList["order"] = { message: "Feld darf nicht leer sein" };
    }

    if (errorList["order"] || errorList["points"]) {
      setErrors(errorList);
      return;
    }

    updateLearningArea({
      variables: { id, headline, points, order, question_count },
    }).then(() => {
      refetchProduct();
      refetch();
      setErrors({});
      errorList = {};
      console.log('closeForm', closeForm);
      // closeForm();
    });
    console.log("UPDATED");
  }

  function handleDelete(id) {
    deleteLearningArea({ variables: { id } }).then(() => {
      refetchProduct();
      refetch();
    });

    console.log("DELETED");
  }

  function handleAddEmptyCard() {
    if (!emptyCards.length) {
      setEmptyCards([getEmptyCard()]);
    } else {
      setEmptyCards([...emptyCards, getEmptyCard()]);
    }
  }

  function handleDeleteEmptyCard(key) {
    setEmptyCards(emptyCards.filter((item) => item.key !== key));
  }

  function goToSubAreas(learningAreaId) {
    history.push(`/learning/${learningAreaId}`);
  }

  return data && !data.learningAreas.length ? (
    <>
      <EmptyLearningCardList
        appElement={appElement}
        emptyCards={emptyCards}
        handleAdd={handleAdd}
        handleAddEmptyCard={handleAddEmptyCard}
        handleDeleteEmptyCard={handleDeleteEmptyCard}
      />
    </>
  ) : (
    <>
      <Grid size="col-6">
        {data &&
          data.learningAreas.map((learningArea, index) => (
            <FilledLearningArea
              hasErrors={errors}
              appElement={appElement}
              history={history}
              key={learningArea.id}
              learningArea={learningArea}
              index={index}
              editableConfig={editableConfig}
              buttonText={t("openLearningArea")}
              learningAreaTotal={data.learningAreas.length || 1}
              handleDelete={handleDelete}
              save={(data, closeForm) => handleUpdate(data, closeForm)}
              handleUpdate={handleUpdate}
              goToSubAreas={goToSubAreas}
              order={learningArea.order}
              questionCount={learningArea.question_count}
            />
          ))}
        {emptyCards.map((card) => (
          <FormWrapper
            appElement={appElement}
            key={card.key}
            handleSubmit={handleAdd}
            initialValues={card}
            editable={editableConfig}
          >
            <LearningAreaCard
              key={card.key}
              headline={card.headline}
              points={card.points}
              buttonText={t("openLearningArea")}
              showActionButtons
              showPoints
              hasErrors={errors}
              save={handleAdd}
              remove={() => handleDeleteEmptyCard(card.key)}
            />
          </FormWrapper>
        ))}
        <AddButton onClick={handleAddEmptyCard} />
      </Grid>
    </>
  );
}

function withRedux({ appElement, product }) {
  return { appElement, product };
}

export default connect(withRedux, null)(withRouter(Learning));
