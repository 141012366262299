import React, { useState } from "react";
import Actions from "@appucations/core/dist/components/molecules/action";
import Banner from "@appucations/core/dist/components/molecules/banner";
import FormWrapper from "@appucations/core/dist/components/organisms/formWrapper";
import Input from "@appucations/core/dist/components/atoms/input";
import { useTranslation } from "react-i18next";
import Select from "@appucations/core/dist/components/atoms/select";
import Checkbox from "@appucations/core/dist/components/atoms/checkbox";
import Headline from "@appucations/core/dist/components/atoms/headline";

export default function UpdateSetting({
  appElement,
  history,
  setting,
  handleUpdate,
  handleDelete,
  handleEdit,
}) {
  const { t } = useTranslation();
  const editableConfig = {
    name: {
      name: "name",
      labelText: t("name"),
    },
    value: {
      name: "value",
      labelText: t("value"),
    },
    type: {
      name: "type",
      labelText: t("type"),
    },
    input_type: {
      name: "input_type",
      labelText: t("inputType"),
    },
  };

  function goToListSettings() {
    history.push(`/settings`);
  }

  return (
    <Banner headline={t("editSetting")} onClick={goToListSettings}>
      <Headline type="h2">{t(setting.name)}</Headline>
      <FormWrapper
        appElement={appElement}
        initialValues={setting}
        handleEdit={handleEdit}
        handleSubmit={handleUpdate}
        handleDelete={handleDelete}
        editable={editableConfig}
        id={setting.id}
      >
        <UpdateForm
          handleSubmit={handleUpdate}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
        />
      </FormWrapper>
    </Banner>
  );
}

function UpdateForm({
  editable,
  values,
  handleSubmit,
  handleDelete,
  handleEdit,
  id,
}) {
  const { t } = useTranslation();

  const typesList = [
    {
      id: "",
      name: t("choose"),
    },
    {
      id: "exam",
      name: t("typeExam"),
    },
  ];

  const inputTypesList = [
    {
      id: "",
      name: t("choose"),
    },
    {
      id: "number",
      name: t("inputTypeNumber"),
    },
    {
      id: "checkbox",
      name: t("inputTypeCheckbox"),
    },
  ];

  const [type, setType] = useState(values.type);
  const [inputType, setInputType] = useState(values.input_type);

  function changeType(e) {
    setType(e.target.value);
  }

  function changeInputType(e) {
    setInputType(e.target.value);
  }

  return (
    <div>
      <Actions
        isEditMode={true}
        edit={handleEdit}
        save={() => handleSubmit(values)}
        id={id}
        remove={handleDelete}
      />
      <Select
        value={type}
        list={typesList}
        onChange={(e) => {
          editable.onChange(e);
          changeType(e);
        }}
        {...editable.type}
        labelText={t("type")}
        required={true}
      />
      {type && (
        <div>
          <Input
            value={values.name}
            onChange={editable.onChange}
            {...editable.name}
            labelText={t("name")}
            required={true}
          />
          <Select
            value={inputType}
            list={inputTypesList}
            onChange={(e) => {
              editable.onChange(e);
              changeInputType(e);
            }}
            {...editable.input_type}
            labelText={t("inputType")}
            required={true}
          />
          {inputType === "number" && (
            <Input
              type="number"
              value={values.value}
              onChange={editable.onChange}
              {...editable.value}
              labelText={t("value")}
              required={true}
            />
          )}
          {inputType === "checkbox" && (
            <Checkbox
              value={values.value}
              onChange={editable.onChange}
              {...editable.value}
              label={values.value === true ? t("yes") : t("no")}
              type="success"
            />
          )}
        </div>
      )}
    </div>
  );
}
