import React from 'react';
import PropTypes from 'prop-types';
import {Wrapper} from './styled';
import Select from "@appucations/core/dist/components/atoms/select";

export default function SelectField({list, value, name, onChange, reload, ...otherProps}) {
    return (
        <>
        <Wrapper>
            <Select
                id={otherProps.id}
                value={value}
                list={list}
                onChange={onChange}
            />
        </Wrapper>
        </>
);
};

SelectField.propTypes = {
    name: PropTypes.string.isRequired,
    list: PropTypes.array,
};

SelectField.defaultProps = {
    list: [],
};
