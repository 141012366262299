import React, { useState } from "react";
import Actions from "@appucations/core/dist/components/molecules/action";
import Banner from "@appucations/core/dist/components/molecules/banner";
import FormWrapper from "@appucations/core/dist/components/organisms/formWrapper";
import { Wrapper } from "../../../infoArea/styled";
import List from "@appucations/core/dist/components/organisms/list";
import { useTranslation } from "react-i18next";
import Wysiwyg from "@appucations/core/dist/components/atoms/wysiwyg";
import getDefaultWysiwygConfig from "../../../../helpers/wysiwyg";
import Input from "@appucations/core/dist/components/atoms/input";
;

export default function UpdateSubAreaItem({
  appElement,
  history,
  id,
  data,
  handleUpdate,
  handleDelete,
  handleEdit,
}) {
  const { t } = useTranslation();
  const editableConfig = {
    headline: {
      name: "headline",
      labelText: t("headline"),
    },
    order: {
      name: "order",
      labelText: t("order"),
    },
  };

  return (
    <Banner headline="Bereich" onClick={() => history.goBack()}>
      <List>
        <Wrapper>
          <FormWrapper
            appElement={appElement}
            initialValues={data.subArea}
            handleEdit={handleEdit}
            handleSubmit={handleUpdate}
            handleDelete={handleDelete}
            editable={editableConfig}
            id={id}
          >
            <UpdateForm
              handleSubmit={handleUpdate}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
            />
          </FormWrapper>
        </Wrapper>
      </List>
    </Banner>
  );
}

function UpdateForm({
  editable,
  values,
  handleSubmit,
  handleDelete,
  handleEdit,
  id,
}) {
  const [headline, setHeadline] = useState(values && values.headline);
  const [order, setOrder] = useState(values && values.order);
console.log('values:' + JSON.stringify(headline) + 'order' + order)
  const wysiwygConfiguration = getDefaultWysiwygConfig();

  return (
    <div>
      <Actions
        isEditMode={true}
        edit={handleEdit}
        save={() => handleSubmit({headline, id, order})}
        id={id}
        remove={handleDelete}
      />
      <Wysiwyg
        data={values && values.headline}
        config={wysiwygConfiguration}
        onChange={(event, editor) => {
          const data = editor.getData();
          setHeadline(data);
        }}
      />
      <Input
      value={order}
      type="text"
      onChange={(e) => setOrder(e.target.value)}   
      {...editable.order}
      />
    </div>
  );
}
