// react
import React from 'react'
import PropTypes from 'prop-types'
// styles
import styled from 'styled-components'
import {colors} from '../../../variables/css'
// components
import {NavLink} from 'react-router-dom'
import {useLocation} from "react-router";
import {useTranslation} from "react-i18next";

const Wrapper = styled.nav`
	//width: 300px;
	height: 100%;
	
	box-sizing: border-box;
	border-right: 2px solid #ced6dc;
	
	background: #f3f3f3;
`;

const Link = styled(NavLink)`
	justify-self: stretch;
	align-self: stretch;
	
	width: 100%;
	height: 50px;
	
	box-sizing: border-box;
	padding: 0 20px;
	border-bottom: 1px solid #ced6dc;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	color: ${colors.dark_grey};
	font-size: 20px;
	text-decoration: none;
	
	&.active,
	:hover {
		color: ${colors.font};
		background: ${({color}) => color};
	}
`;

Link.propTypes = {
    color: PropTypes.string,
};

Link.defaultProps = {
    color: colors.dark_grey
};

function Component({children, ...otherProps}) {
    const {t} = useTranslation();
    const location = useLocation();

    function isLearningActive() {
        return location.pathname.startsWith('/learning') || location.pathname.startsWith('/learning-content')
    }

    function isExamsActive() {
        return location.pathname.startsWith('/question') || location.pathname.startsWith('/exams')
    }

    return (
        <Wrapper {...otherProps}>
            <Link isActive={isLearningActive} to="/learning">{ t('learning') }</Link>
            <Link isActive={isExamsActive} to="/exams">{ t('examine') }</Link>
            <Link to="/info">{ t('info') }</Link>
            <Link to="/settings">{ t('settings') }</Link>
            {/* <Link to="/flash-cards" color={ colors.blue }>Lernkarten</Link>
			 */}
        </Wrapper>
    )
}

Component.propTypes = {};

Component.defaultProps = {};

export default Component
