import styled from 'styled-components';
import Actions from "@appucations/core/dist/components/molecules/action";

export const Headline = styled.div`
    display: grid;
    text-align: center;
    h2 {
        color: #20415D;
        font-size: 32px;
        margin: 0;
    }
`;

export const SubHeadline = styled.div`
    display: grid;
    color: #339BF2;
    font-size: 11px;
    text-transform: uppercase;
`;
