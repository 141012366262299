import React, {useEffect} from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { useQuery } from "react-apollo";
import { LearningArea, Product, SubArea } from "@appucations/common-api";
import { useMutation } from "@apollo/react-hooks";
import CreateSubAreaItem from "../../../../components/learningArea/panel/subareaItem/create";

function CreateSubArea({ appElement, product, history, ...otherProps }) {
  const learningAreaId = otherProps.match.params.learningAreaId || null;
  const subAreaId = otherProps.match.params.subAreaId || null;
  const { loading: productIsLoading } = useQuery(Product.getQuery(), {
    variables: { id: product.id },
  });
  const [createSubArea] = useMutation(SubArea.createMutation());

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        console.log('Enter Pressed');
        document.querySelector(".banner .round-button--is-success").click()
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  const {
    loading: loadingSubArea,
    data: dataSubArea,
    refetch: refetchSubArea,
  } = useQuery(SubArea.getQuery(), {
    variables: { id: subAreaId },
    skip: !subAreaId,
  });
  if (dataSubArea) {
    console.log("subarea", dataSubArea);
  }
  const { loading, data, refetch } = useQuery(LearningArea.getQuery(), {
    variables: { id: learningAreaId },
  });

  if (productIsLoading) {
    return <div>Loading</div>;
  }

  function handleCreate(data) {
    const { headline, order } = data;
    console.log(JSON.stringify(data), order);
    let newHeadline = JSON.parse(JSON.stringify(headline.replace(/<[^>]*>?/gm, '')));
    createSubArea({
      variables: {
        learning_area_id: learningAreaId,
        headline : newHeadline,
        sub_area_id: subAreaId,
        order: order,
      },
    }).then(close);
  }

  function close() {
    if (!subAreaId) {
      history.push(`/learning/${learningAreaId}`);
    } else {
      history.push(`/learning/${learningAreaId}/${subAreaId}`);
    }
  }
  function buttonClicked() {
    history.push(`/learning/${learningAreaId}`);
  }
  return (

    <>
      <>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a onClick={buttonClicked}>{data && data.learningArea.headline && data.learningArea.headline.replace(/<[^>]*>?/gm, '').replaceAll('&shy;', '').replaceAll('&nbsp;','')}</a></li>
            <li className="breadcrumb-item">{dataSubArea && dataSubArea.subArea.headline.replace(/<[^>]*>?/gm, '').replaceAll('&shy;', '').replaceAll('&nbsp;','')}</li>
          </ol>
        </nav>
      </>


      <CreateSubAreaItem
        appElement={appElement}
        history={history}
        handleCreate={handleCreate}
        handleEdit={close}
      />
    </>
  );
}

function withRedux({ appElement, product }) {
  return { appElement, product };
}

export default connect(withRedux, null)(withRouter(CreateSubArea));
