import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {useQuery} from 'react-apollo';
import {Product, SubArea} from "@appucations/common-api";
import {useMutation} from "@apollo/react-hooks";
import UpdateSubAreaItem from "../../../../components/learningArea/panel/subareaItem/update";
import { useTranslation } from "react-i18next";

import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function UpdateSubArea({ appElement, product, history, ...otherProps }) {
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const learningAreaId = otherProps.match.params.learningAreaId || null;
    const subAreaId = otherProps.match.params.subAreaId || null;
    const { loading: productIsLoading } = useQuery(Product.getQuery(), {variables: {id: product.id}});
    const {data, loading: subAreaIsLoading, refetch} = useQuery(SubArea.getQuery(), {variables: {id: subAreaId}});
    const [deleteSubArea] = useMutation(SubArea.deleteMutation());
    const [updateSubArea] = useMutation(SubArea.updateMutation());

    const { t } = useTranslation();

    useEffect(() => {
        refetch();
    });

    if (productIsLoading || subAreaIsLoading) {
        return <div>Loading</div>;
    }

    function handleUpdate(data) {
        const { headline, id, order } = data;
        updateSubArea({ variables: { id, headline, order:order } }).then(close);
    }

    function handleDelete(id) {
        deleteSubArea({ variables: { id } }).then(close);
    }

    function close() {
        const parentSubArea = data.subArea.parentCategory;
        const parentSubAreaId = parentSubArea ? parentSubArea.id : null;

        if (!parentSubAreaId) {
            history.push(`/learning/${learningAreaId}`);
        } else {
            history.push(`/learning/${learningAreaId}/${parentSubAreaId}`);
        }
    }

    return (
        <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Body>{t("sure?")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("close")}
          </Button>
          <Button variant="primary"onClick={() =>{ handleDelete(subAreaId); setShowModal(false)}}>{t("delete")}</Button>
        </Modal.Footer>
      </Modal>        
        <UpdateSubAreaItem
            appElement={appElement}
            history={history}
            id={subAreaId}
            data={data}
            handleEdit={close}
            handleUpdate={handleUpdate}
            handleDelete={() => {
                setShowModal(true);
              }}
            setIsEdit={close}
        />
        </>
    )
}

function withRedux({ appElement, product }) {
    return { appElement, product }
}

export default connect(withRedux, null)(withRouter(UpdateSubArea));
