import {SET_GLOBAL_ERROR, CLEAR_GLOBAL_ERRORS} from "../types";

const initialState = {
  errors: [],
};

export default function globalError(state = initialState, action) {
    switch(action.type) {
        case SET_GLOBAL_ERROR: {
            return [];
/*            let allErrors = [];

            const graphQLErrors = action.payload.graphQLErrors;
            if (graphQLErrors) {
                allErrors.push.apply(allErrors, graphQLErrors.map(error => ({
                    statusCode: 200,
                    message: error.message,
                    debugMessage: error.debugMessage,
                })));
            }

            const networkError = action.payload.networkError;
            if (networkError) {
                allErrors.push({
                    statusCode: networkError.statusCode,
                    message: networkError.name,
                    debugMessage: networkError.result.message,
                });
            }

            return {
                ...state,
                errors: allErrors,
            }*/
        }
        case CLEAR_GLOBAL_ERRORS: {
            return initialState;
        }
        default:
            return state;
    }
}
