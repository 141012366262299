import React from 'react';
import ShowLearningContent from "./index";

export default function LearningContentList({ appElement, history, lastLocation, data }) {
    return (
        <div>
            {
                data && data.map(learningContent => (
                    <ShowLearningContent
                        key={learningContent.id}
                        lastLocation={lastLocation}
                        appElement={appElement}
                        history={history}
                        data={learningContent}
                    />
                ))
            }
        </div>
    );
}
