// todo: icon left and right
// react
import React from 'react'
import PropTypes from 'prop-types'
// styles
import styled from 'styled-components'
import classNames from 'classnames'
import { colors, sizes } from '../../variables/css';
import { Wrapper as WrapperPrimary } from './primary';

const { buttons, fonts, icons } = sizes;

const Wrapper = styled(WrapperPrimary)`
    &.size-small {
        display: grid;
        width: 49px;
        min-height: 25px;
        height: 25px;
        padding: 0;
        background-color: #EEE;
        border-radius: 0;
        span {
            color: black;
        }
        svg {
            width: 15px;
        }
    }
`;

const Name = styled.span`
    color: ${ ({ color }) => color };
    font-size: ${ fonts["default"] };
    letter-spacing: 3px;
`;

function Component({ isBlock, size, color, IconLeft, IconRight, children: name, ...otherProps }) {
    return (
        <Wrapper { ...otherProps } className={ classNames({ "is-block": isBlock }, `size-${ size }`)}>
            { !!IconLeft && ( <IconLeft width="25px" fill={ color } /> ) }
            <Name className={`size-${ size }`} color={ color }>{ name && name.hasOwnProperty() ? name.toUpperCase() : name }</Name>
            { !!IconRight && ( <IconRight width="25px" fill={ color } /> ) }
        </Wrapper>
    )
}

Component.propTypes = {
    size: PropTypes.oneOf(["large", "default", "small"]),
    color: PropTypes.string,
};

Component.defaultProps = {
    size: "default",
    color: colors.blue
};

export default Component
