import React from 'react';
import {getEmptyCard} from "../../pages/learning/data";
import AddButton from "../../components/buttons/round/addButton";
import LearningAreaCard from "@appucations/core/dist/components/molecules/learningAreaCard";
import Grid from "@appucations/core/dist/components/organisms/grid";
import FormWrapper from "@appucations/core/dist/components/organisms/formWrapper";
import {useTranslation} from "react-i18next";
import getDefaultWysiwygConfig from "../../helpers/wysiwyg";

export default function EmptyLearningCardList({appElement, handleAdd, emptyCards, handleAddEmptyCard, handleDeleteEmptyCard}) {
    const {t} = useTranslation();
    const editableConfig = {
        headline: {
            name: 'headline',
            labelText: t('headline')
        },
        points: {
            name: 'points',
            labelText: t('points'),
        },
        order: {
            name: 'order',
            labelText: t('order'),
        },
        question_count: {
            name: 'question_count',
            labelText: t('question_count'),
        },
        wysiwygConfig: getDefaultWysiwygConfig(),
    };
    const buttonText = t('openLearningArea');
    return (
        <Grid size="col-6">
            <FormWrapper
                appElement={appElement}
                handleSubmit={handleAdd}
                initialValues={getEmptyCard()}
                editable={editableConfig}
            >
                <LearningAreaCard
                    buttonText={buttonText}
                    showActionButtons
                    showPoints
                />
            </FormWrapper>

            {emptyCards.map((card) => (
                <FormWrapper
                    appElement={appElement}
                    key={card.key}
                    handleSubmit={handleAdd}
                    initialValues={card}
                    editable={editableConfig}
                >
                    <LearningAreaCard
                        headline={card.headline}
                        points={card.points}
                        buttonText={buttonText}
                        showActionButtons
                        showPoints
                        remove={() => handleDeleteEmptyCard(card.key)}
                    />
                </FormWrapper>
            ))}
            <AddButton onClick={handleAddEmptyCard}/>
        </Grid>
    );
}
