import styled from "styled-components";
import {colors} from "../../../../variables/css";
import {fadeIn} from "react-animations";

export const Button = styled.div`
    position: fixed;
    right: 20px;
    top: calc(100vh - 90px - 45px);
    
    min-width: 45px;
    min-height: 45px;
    
    box-sizing: border-box;
    padding: 5px;
    border-radius: 50%;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    background: ${ colors.blue };
    box-shadow:
      0 0 50px 0 rgba(0,0,0,.5),
      0 0 10px 0 rgba(0,0,0,.3);
    
    animation: ${fadeIn} 1s ease-in-out;
    
    > svg {
        width: 25px;
        fill: #fff;
    }
    &.secondary {
        right: 70px;
        background: ${ colors.green };
    } 
    :hover {
        cursor: pointer;
    }
`;
