import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { useQuery } from "@apollo/react-hooks";
import Grid from "@appucations/core/dist/components/organisms/grid";
import Product from "@appucations/common-api/dist/graphql/product";
import { useTranslation } from "react-i18next";
import { LearningArea } from "@appucations/common-api";
import ExerciseCard from "@appucations/core/dist/components/molecules/exerciseCard";

function Examing({ product, history }) {
  const { t } = useTranslation();
  const { loading: productLoading } = useQuery(Product.getQuery(), {
    variables: { id: product.id },
  });
  const { loading, data } = useQuery(LearningArea.getAllByProductIdQuery(), {
    variables: { product_id: product.id,
                 requester: "autorentool" },
                });

  if (productLoading || loading) return <p>Loading ...</p>;

  function goToLearningArea(learningAreaId) {
    history.push(`/questions/${learningAreaId}`);
  }

  return (
    <Grid size="col-6">
      {data &&
        data.learningAreas.map((learningArea, index) => (
          <ExerciseCard
            key={`exercise-area-${learningArea.id}`}
            headline={learningArea.headline}
            description={
              learningArea.questions.length === 1
                ? t("countQuestion", {
                    count: learningArea.questions.length,
                  })
                : t("countQuestions", {
                    count: learningArea.questions.length,
                  })
            }
            header={t("learningAreaHeader", {
              count: index + 1,
              countTotal: data.learningAreas.length,
            })}
            buttonText={t("openLearningArea")}
            onClick={() => goToLearningArea(learningArea.id)}
          />
        ))}
    </Grid>
  );
}

function withRedux({ product }) {
  return { product };
}

export default connect(withRedux, null)(withRouter(Examing));
