import React from 'react'


export default props => (
    <svg viewBox="0 0 160 160" { ...props }>
        <g fill="none">
            <path fill="#74B41F" d="M0.00174518994,150.222973 C-0.0984183141,155.52173 4.12550017,159.817222 9.42005143,159.817222 L150.400332,159.817222 C155.702082,159.817222 160,155.528733 160,150.207357 L160,38.3192687 C160,38.3192687 125.741925,38.3192687 107.221159,64.8023847 C88.7003934,91.2855007 83.071478,116.301156 55.525867,128.085149 C27.980256,139.869142 0.197465715,139.869145 0.197465715,139.869145 L0.00174518994,150.222973 Z"/>
            <path fill="#F8AA0F" d="M159.998453,8.48870553 C160.087063,3.80052292 156.357859,1.13686838e-13 151.647039,1.13686838e-13 L8.51185732,1.13686838e-13 C3.81088833,1.13686838e-13 2.84217094e-14,3.80307317 2.84217094e-14,8.48315596 L2.84217094e-14,121.497953 C2.84217094e-14,121.497953 34.253643,121.497953 52.7720132,95.0148374 C71.2903833,68.5317214 76.9185706,43.5160665 104.460618,31.7320732 C132.002666,19.94808 159.781862,19.9480766 159.781862,19.9480766 L159.998453,8.48870553 Z"/>
        </g>
    </svg>
)