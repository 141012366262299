import React, {useState} from 'react';
import {connect} from "react-redux";
import {useParams, withRouter} from "react-router";
import WrappedQuestionForm from "@appucations/core/dist/components/organisms/questionForm";
import {getEmptyQuestion} from "../../../../components/examArea/question/data";
import {useMutation, useQuery} from "react-apollo";
import {Product, Question} from "@appucations/common-api";
import Banner from "@appucations/core/dist/components/molecules/banner";
import {useTranslation} from "react-i18next";
import AddButton from "../../../../components/buttons/round/addButton";
import {Button} from "../../../../components/buttons/round/addButton/styled";
import Plus from "../../../../components/icons/plus";
const SUBMIT_TYPE = Object.freeze({
    SAVE : 'save',
    PLUS : 'plus'
});
export function CreateQuestion({appElement, product, history}) {
    const {t} = useTranslation();
    const {learning_area_id} = useParams();
    const {loading} = useQuery(Product.getQuery(), {variables: {id: product.id}});
    const [createQuestion] = useMutation(Question.createMutation());
    const question = getEmptyQuestion();
    const [submitType, setSubmitType] = useState(SUBMIT_TYPE.SAVE);

    if (loading || !question) {
        return <div>Loading</div>
    }

    function close() {
        history.push(`/questions/${learning_area_id}`);
    }

    function save(data) {
        const {question: {question, points, answers, type, is_exercisable}} = data;
        let answersInput = answers.map(answer => ({
            is_correct: answer.is_correct,
            text: answer.text,
            order: answer.order,
            points: answer.points,
        }));

        createQuestion({
            variables: {
                question,
                points,
                answers: answersInput,
                type,
                is_exercisable,
                learning_area_id,
            },
        }).then(() => {
            if (submitType === SUBMIT_TYPE.SAVE) {
                close();
                return;
            }
            window.location.href = `/question/create/${learning_area_id}`;
        });
    }

    function addButton(){
        document.querySelector(".question-form .round-button--is-success").click()
    }

    return (
        <Banner headline={ t('examine') } onClick={close}>
            <Button className={'add-question'} onClick={() => {
                setSubmitType(SUBMIT_TYPE.PLUS);
                addButton();
            }}><Plus /></Button>

            <WrappedQuestionForm
                appElement={appElement}
                question={question}
                close={close}
                handleSubmit={save}
            />
        </Banner>
    );
}

export default connect(({appElement, product}) => ({appElement, product}))(withRouter(CreateQuestion));

