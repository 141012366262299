import styled from 'styled-components';

export const Wrapper = styled.div`
  select {
    background-image: unset !important;
    text-align-last: center;
    width: 101% !important;
    margin-left: 220px;

  }
    display: flex;
    grid-template-columns: 1fr 20px;
    svg {
        margin: auto 0;
        path {
            width: 24px;
        }
    }
`;
