import React from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import {ApolloProvider} from '@apollo/react-hooks'
import {client} from './data/graphql';
import {CookiesProvider} from 'react-cookie'
import TranslationProvider from "@appucations/core/dist/components/organisms/translationProvider";
import de from "./i18n/de";
import en from "./i18n/en";
import Routes from "./routes";

function App() {
    return (
        <TranslationProvider translationsObject={{de, en}}>
            <CookiesProvider>
                <ApolloProvider client={client}>
                    <Router>
                        <Routes/>
                    </Router>
                </ApolloProvider>
            </CookiesProvider>
        </TranslationProvider>
    )
}

export default App;
