import React from 'react';
import {useParams, withRouter} from "react-router";
import {connect} from "react-redux";
import {useQuery} from "@apollo/react-hooks";
// @TODO: this should use the dist files instead of src; good enough for now, but needs cleanup later on
import {LearningArea, LearningContent, Product, SubArea} from "@appucations/common-api";
import LearningContentListing from "../../../../../components/learningArea/content/list";

export function LearningContentList({ appElement, product, history, lastLocation }) {
    let { learningAreaId,subAreaId } = useParams();
    const { data, loading: learningContentIsLoading } = useQuery(LearningContent.getAllBySubAreaIdQuery(), { variables: { sub_area_id: subAreaId, return_all_siblings: false }});
    const { loading: productIsLoading } = useQuery(Product.getQuery(), {variables: {id: product.id}});
    const { loading, data: learningAreaData, refetch } = useQuery(LearningArea.getQuery(), {
        variables: { id: learningAreaId }, skip: !learningAreaId,
    });
    const {
        loading: loadingSubArea,
        data: dataSubArea,
        refetch: refetchSubArea,
    } = useQuery(SubArea.getQuery(), {
        variables: { id: subAreaId },
        skip: !subAreaId,
    });

    if (learningContentIsLoading || productIsLoading) {
        return <div>Loading</div>;
    }


    function buttonClicked() {
        history.push(`/learning/${learningAreaId}`);
    }

    return <>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><a onClick={buttonClicked}>{learningAreaData && learningAreaData.learningArea.headline && learningAreaData.learningArea.headline.replace(/<[^>]*>?/gm, '').replaceAll('&shy;', '').replaceAll('&nbsp;','')}</a></li>
                <li className="breadcrumb-item">{dataSubArea && dataSubArea.subArea.headline.replace(/<[^>]*>?/gm, '').replaceAll('&shy;', '').replaceAll('&nbsp;','')}</li>
            </ol>
        </nav>

    {data && data.learningContents && (
        <LearningContentListing
         history={history}
         appElement={appElement}
         lastLocation={lastLocation}
         data={data.learningContents}
         />
        )}
    </>
}

function withRedux({ appElement, product, lastLocation }) {
    return { appElement, product, lastLocation }
}

export default connect(withRedux, null)(withRouter(LearningContentList));
