import React from 'react';
import {Button} from "./styled";
import {FileEarmarkPlus} from "react-bootstrap-icons";

export default function AddButton({ onClick, className, ...otherProps }) {
    return (
        <Button className={className} onClick={onClick} {...otherProps}><FileEarmarkPlus /></Button>
    )
}


