import React, {useEffect} from 'react';
import {useParams, withRouter} from "react-router";
import {connect} from "react-redux";
import {useMutation, useQuery} from "@apollo/react-hooks";
// @TODO: this should use the dist files instead of src; good enough for now, but needs cleanup later on
import {LearningArea, LearningContent as LearningContentApi, Product, SubArea} from "@appucations/common-api";
import UpdateLearningContentComponent from "../../../../../components/learningArea/content/update";

export function UpdateLearningContent({ appElement, product, history }) {
    let { subAreaId, learningAreaId } = useParams();
    const [updateLearningContent] = useMutation(LearningContentApi.updateMutation());
    const [deleteLearningContent] = useMutation(LearningContentApi.deleteMutation());
    const { data, loading: learningContentIsLoading, refetch } = useQuery(LearningContentApi.getAllBySubAreaIdQuery(), { variables: { sub_area_id: subAreaId, return_all_siblings: false }});
    const { loading: productIsLoading } = useQuery(Product.getQuery(), {variables: {id: product.id}});
    const { loading, data: learningAreaData, refetch: refetchLearningArea } = useQuery(LearningArea.getQuery(), {
        variables: { id: learningAreaId }, skip: !learningAreaId,
    });
    const {
        loading: loadingSubArea,
        data: dataSubArea,
        refetch: refetchSubArea,
    } = useQuery(SubArea.getQuery(), {
        variables: { id: subAreaId },
        skip: !subAreaId,
    });



    if (learningContentIsLoading || productIsLoading) {
        return <div>Loading</div>;
    }

    function handleUpdate(data) {
        const { id, headline, sub_area_id, wysiwyg, reading_time } = data;
        let time = Math.floor(reading_time * 60);
        updateLearningContent({ variables: { id, headline, sub_area_id, wysiwyg, reading_time: time } }).then(close);
    }

    function handleDelete(id) {
        deleteLearningContent({ variables: { id } }).then(() => {
            console.log('DELETEDD')
            history.push(`/learning/${learningAreaId}/${subAreaId}`);
        }).catch(() => {
            history.push(`/learning/${learningAreaId}/${subAreaId}`);
        });
    }

    function close() {
        history.push(`/learning/${learningAreaId}/${subAreaId}`);
    }

    function buttonClicked() {
        history.push(`/learning/${learningAreaId}`);
    }

    return (
        <>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a onClick={buttonClicked}>{learningAreaData && learningAreaData.learningArea.headline && learningAreaData.learningArea.headline.replace(/<[^>]*>?/gm, '').replaceAll('&shy;', '').replaceAll('&nbsp;','')}</a></li>
                    <li className="breadcrumb-item">{dataSubArea && dataSubArea.subArea.headline.replace(/<[^>]*>?/gm, '').replaceAll('&shy;', '').replaceAll('&nbsp;','')}</li>
                </ol>
            </nav>
            <UpdateLearningContentComponent
                history={history}
                appElement={appElement}
                id={subAreaId}
                data={data}
                handleEdit={close}
                handleUpdate={handleUpdate}
                handleDelete={handleDelete}
            />
        </>
    )
}

function withRedux({ appElement, product }) {
    return { appElement, product }
}

export default connect(withRedux, null)(withRouter(UpdateLearningContent));
