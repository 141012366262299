import enComponents from "@appucations/core/dist/translation/languages/en";

export default {
    translation: {
        ...enComponents.translation,
        'headline': 'headline',
        'dauer': 'duration',
        'richtige_antworten_anzeigen': 'is showing correct answers count',
        'mindestquote_zum_bestehen': 'pass at minimum percentage',
        'points': 'points',
        'wysiwyg': 'text',
        'openLearningArea': 'open learning area',
        'learningAreaHeader': 'learning area {{count}} / {{countTotal}}',
        'learningContent': 'learning content',
        'learningContents': 'learning contents',
        'openLearningContent': 'learn',
        'addLearningContent': 'add learning content',
        'learningSubArea': 'learning sub area',
        'learningSubAreas': 'learning sub areas',
        'openSubArea': 'open sub area',
        'addSubArea': 'add sub area',
        'addLearningArea': 'add learning area',
        'examine': 'examine',
        'search': 'search',
        'enterQuestion': 'enter question ...',
        'uncategorized': 'uncategorized',
        'answer': 'answer',
        'answers': 'answers',
        'learning': 'learning',
        'info': 'info',
        'generalInstructions': 'general instructions',
        'writtenExamInstructions': 'written exam instructions',
        'oralExamInstructions': 'oral exam instructions',
        'generalErrorTitle': 'error',
        'generalErrorDescription': 'A general error occured:',
        'countQuestion': '{{count}} question',
        'countQuestions': '{{count}} questions',
        'createSetting': 'create setting',
        'editSetting': 'edit setting',
        'settings': 'settings',
        'name': 'name',
        'value': 'value',
        'type': 'type',
        'typeExam': 'type exam',
        'inputType': 'input type',
        'inputTypeNumber': 'number',
        'inputTypeCheckbox': 'checkbox',
        'choose': 'choose',
        'yes': 'yes',
        'no': 'no',
        'sure?': 'Do you want to delete this item?',
        'order': 'Order: {{count}}',
        'question_count': 'Count: {{count}}',
        'close': 'Close',
        'delete': 'Delete',
    }
}
