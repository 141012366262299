// react
import React, {useEffect, useReducer, useState} from 'react'
import PropTypes from 'prop-types'
// styles
import styled from 'styled-components'
import {colors} from '../../variables/css'
// hoc
import {Form, Formik} from 'formik'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
// components
import Error from '../error'
import Loading from '../loading'
import {Default, Primary as Button} from '../../components/buttons'
import Logo from '../../components/icons/appucations'
// data
import {getUserDetails, login} from '../../data'
// helper
import {signIn} from '../../redux/actions'
import * as Yup from 'yup'

const Wrapper = styled(Form)`
    width: 100%;
    height: 100%;
    
    box-sizing: border-box;
    padding: 50px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    > * {
        margin: 20px 0;
    }
`;

const Heading = styled.h1`
    white-space: nowrap;
    color: ${ colors.dark_blue};
    font-size: 30px;
`;

const Buttons = styled.div`
    width: 100%;
    height: auto;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    > *[type="submit"] {
        margin: 10px;
    }
`;

const Input = styled.input`
  width: 30%;
  height: 30px;
  box-sizing: border-box;
  padding: 24px 12px;
  border-left: none;
  border-top: none;
  border-right: none;
  border-bottom: 2px solid rgb(237,237,237);
  color: rgb(31,65,92);
  font-size: 16px;
  background: transparent;
`;

const initialValues = {
    email: "",
    password: ""
};

const validationSchema = Yup.object().shape({
    email: Yup.string().email("Deine E-Mail ist ungültig").required("E-Mail ist ein Pflichtfeld"),
    password: Yup.string().required("Passwort ist ein Pflichtfeld")
});

function SignIn({ signIn, user, lastLocation, history, ...otherProps }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useReducer(errorReducer, null);

    useEffect(() => {
        if (user && user.authenticationToken && user.id) {
            history.push(lastLocation.pathname);
        }
    }, [user]);
    function errorReducer(state, error) {
        setLoading(false);
        return error
    }

    function saveUserDetails(token) {
        return function (user) {
            signIn({ user, token });
        }
    }

    function errorHandler(error) {
        setLoading(false);
        setError(error)
    }

    function submit(values, { setSubmitting }) {
        setLoading(true);
        const data = new FormData();
        data.set('email', values.email);
        data.set('password', values.password);
        login(values)
            .then(token => {
                getUserDetails(token)
                    .then(saveUserDetails(token))
                    .catch(errorHandler)
            })
            .catch(setError);
        setSubmitting(false)
    }

    function reset() {
        setLoading(false);
        setError(null)
    }

    function redirectToSettingEmailForPasswordReset() {
        history.push('/password-reset')
    }

    if (loading) return (<Loading />);

    if (error) return (
        <Error
            title="Anmeldung Fehlgeschlagen"
            description="Etwas ist schief gelaufen. Bitte versuche es erneut, dich an zu melden."
            buttonMessage="Ok"
            onAccept={reset}
        />
    );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnBlur={false} validateOnChange={true}
            onSubmit={submit}
            render={({ values, errors, handleChange, handleSubmit }) => {
                return (
                    <Wrapper {...otherProps}>
                        <Logo width="100px" />
                        <Heading>Autoren-Tool</Heading>
                        <Input name="email" placeholder="E-Mail" value={values["email"]} onChange={handleChange} />
                        <Input name="password" type="password" placeholder="Passwort" value={values["password"]} onChange={handleChange} />
                        <Buttons>
                            <Button type="submit" onClick={handleSubmit}>Anmelden</Button>
                            <Default onClick={redirectToSettingEmailForPasswordReset}>Passwort Vergessen</Default>
                        </Buttons>
                    </Wrapper>
                )
            }}
        />
    )
}


SignIn.propTypes = {
    signIn: PropTypes.func.isRequired,
    // history: PropTypes.instanceof( ??? ) todo
};
function withRedux({ user, lastLocation }) {
    return { user, lastLocation }
}

export default connect(withRedux, { signIn })(withRouter(SignIn))
