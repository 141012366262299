import React from 'react';
import LearningContent from "@appucations/core/dist/components/molecules/learningContent";
import Preview from "@appucations/core/dist/components/molecules/modal/preview";
import isInsideIframe from "../../../helpers/window";
import Actions from "@appucations/core/dist/components/molecules/action";
import Banner from "@appucations/core/dist/components/molecules/banner";
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";

export default function ShowLearningContent({ appElement, lastLocation, data, history }) {
    const {t} = useTranslation();
    const { subAreaId, learningAreaId } = useParams();

    function goToEditLearningContent() {
        history.push(`/learning-content/${learningAreaId}/${subAreaId}/edit`);
    }

    return (
        <Banner headline={ t('learningContent') } onClick={() => history.goBack()}>
            { !isInsideIframe() && (
                <Actions isEditMode={false} edit={goToEditLearningContent}>
                    <Preview appElement={appElement} url={lastLocation.pathname} />
                </Actions>
            ) }
            <LearningContent
                headline={data.headline}
            >
                <div dangerouslySetInnerHTML={{ __html: data.wysiwyg }} />
            </LearningContent>
        </Banner>
    );
}
