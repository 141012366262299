import React from "react";
import Banner from "@appucations/core/dist/components/molecules/banner";
import List from "@appucations/core/dist/components/organisms/list";
import { useTranslation } from "react-i18next";
import { ListItemWrapper } from "../list/styled";
import AddButton from "../buttons/round/addButton";
import Actions from "@appucations/core/dist/components/molecules/action";
import ListItem from "@appucations/core/dist/components/molecules/listItem";
import LearningContent from "@appucations/core/dist/components/molecules/learningContent";
import FilledLearningArea from "../learningArea/filledLearningArea";

export default function SettingsList({ history, settings }) {
  const { t } = useTranslation();

  function goToCreateSetting() {
    history.push(`/settings/create`);
  }

  function goToEditSetting(id) {
    history.push(`/settings/${id}/edit`);
  }

  function reverseTransformValue(value, inputType) {
    if (inputType === "checkbox") {
      return value === "true" ? t("yes") : t("no");
    }

    return value;
  }

  function getHeadlineByType(type) {
    const translationKey = `type${
      type.charAt(0).toUpperCase() + type.slice(1)
    }`;

    return t(translationKey);
  }

  function groupSettings(settings) {
    return settings.reduce(function (r, a) {
      let type = a.type;

      r[type] = r[type] || [];
      r[type].push(a);

      return r;
    }, []);
  }

  const groupedSettings = groupSettings(settings);

  return (
    <Banner headline={t("settings")} onClick={() => history.goBack()}>
      {Object.entries(groupedSettings).map(([type, settings]) => (
        <List key={`settings-type-${type}`}>
          <LearningContent headline={getHeadlineByType(type)} />
          {settings.map((setting) => (
            <ListItemWrapper key={`setting-${setting.id}`}>
              <Actions
                isEditMode={false}
                edit={() => goToEditSetting(setting.id)}
              />
              <ListItem
                title={t(setting.name)}
                subTitle={reverseTransformValue(
                  setting.value,
                  setting.input_type
                )}
                removeButton
              />
            </ListItemWrapper>
          ))}
        </List>
      ))}

      <AddButton onClick={goToCreateSetting} />
    </Banner>
  );
}
