import React from 'react';
import {useParams} from "react-router";
import Actions from "@appucations/core/dist/components/molecules/action";
import ListItem from "@appucations/core/dist/components/molecules/listItem";
import {ListItemWrapper} from "../../../list/styled";
import {useTranslation} from "react-i18next";

export default function SubAreaItem({ history, subarea }) {
    const {t} = useTranslation();
    const { id } = subarea;
    const { learningAreaId } = useParams();
    const isLearningContentsSet = subarea && subarea.learningContents && subarea.learningContents.length;
    const isSubAreasSet = subarea && subarea.childCategories && subarea.childCategories.length;
    const enterAreaHeadlineText = t('enterAreaHeadline');
    const isContent = subarea.childCategories && !subarea.childCategories.length && isLearningContentsSet;

    function goTo() {
        if (isContent) {
            history.push(`/learning-content/${learningAreaId}/${id}`)
        } else {
            history.push(`/learning/${learningAreaId}/${id}`)
        }
    }

    function goToEditSubArea() {
        history.push(`/learning/${learningAreaId}/${id}/edit`)
    }

    function getSubAreasChildCount() {
        return isSubAreasSet ? subarea.childCategories.length : 0;
    }

    function getLearningContentsChildCount() {
        return isLearningContentsSet ? subarea.learningContents.length : 0;
    }

    function getChildCountLabel() {
        const subAreasChildCount = getSubAreasChildCount();
        const learningContentsChildCount = getLearningContentsChildCount();

        const labelParts = [
            learningContentsChildCount + ' ' + (learningContentsChildCount === 1 ? t('learningContent') : t('learningContents')),
            subAreasChildCount + ' ' + t('learningSubAreas'),
        ];

        return labelParts.join(', ');
    }

    return (
        <ListItemWrapper>
            <Actions isEditMode={false} edit={goToEditSubArea}/>
            {
                id ? (
                    <ListItem
                        title={subarea.headline || enterAreaHeadlineText}
                        subTitle={getChildCountLabel()}
                        onClick={goTo}
                        buttonIcon={'arrow'}
                        buttonText={isContent ? t('openLearningContent') : t('openSubArea')}
                    />
                ) : (
                    <ListItem
                        title={subarea.headline || enterAreaHeadlineText}
                        subTitle={getChildCountLabel()}
                        removeButton
                    />
                )
            }
        </ListItemWrapper>
    );
}
