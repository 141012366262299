import axios from 'axios'


export const api = axios.create({
	baseURL: `${ process.env.REACT_APP_SERVER }/api`
});


export function connectionTest() {
	return api.options('/');
}