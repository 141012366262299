import React, {useState} from 'react';
import {Wrapper} from './styled';
import Wysiwyg from '@appucations/core/dist/components/atoms/wysiwyg';
import Actions from "@appucations/core/dist/components/molecules/action";
import LearningContent from "@appucations/core/dist/components/molecules/learningContent";
import isInsideIframe from "../../helpers/window";
import Preview from "@appucations/core/dist/components/molecules/modal/preview";
import FormWrapper from "@appucations/core/dist/components/organisms/formWrapper";
import {useTranslation} from "react-i18next";
import getDefaultWysiwygConfig from "../../helpers/wysiwyg";

export default function InfoArea({ appElement, product, lastLocation, handleSubmit }) {
    const [isEdit, setIsEdit] = useState(false);
    if (!product) return null;

    return isEdit
        ? (<RenderEdit handleSubmit={(data) => {
            handleSubmit(data);
            setIsEdit(false);
        }} product={product} handleEdit={setIsEdit} />)
        : (<RenderShow appElement={appElement} product={product} lastLocation={lastLocation} isEdit={isEdit} setIsEdit={setIsEdit} />)
}

function RenderShow({ appElement, product, lastLocation, isEdit, setIsEdit }) {
    const {t} = useTranslation();
    const { general_instructions, written_exam_instructions, oral_exam_instructions, thanks_page, partner_page } = product;
    return (
        <Wrapper>
            { !isInsideIframe() && (
                <Actions isEditMode={isEdit} edit={setIsEdit}>
                    <Preview appElement={appElement} url={lastLocation.pathname} />
                </Actions>
            ) }
            <LearningContent headline={ t('generalInstructions') }>
                <div dangerouslySetInnerHTML={{ __html: general_instructions }} />
            </LearningContent>

            <LearningContent headline={ t('writtenExamInstructions') }>
                <div dangerouslySetInnerHTML={{ __html: written_exam_instructions }} />
            </LearningContent>

            <LearningContent headline={ t('oralExamInstructions') }>
                <div dangerouslySetInnerHTML={{ __html: oral_exam_instructions }} />
            </LearningContent>

            <LearningContent headline={ t('thanksPage') }>
                <div dangerouslySetInnerHTML={{ __html: thanks_page }} />
            </LearningContent>

            <LearningContent headline={ t('partnerPage') }>
                <div dangerouslySetInnerHTML={{ __html: partner_page }} />
            </LearningContent>
        </Wrapper>
    );
}

function RenderEdit({ appElement, product, handleEdit, handleSubmit }) {
    const {t} = useTranslation();
    const editableConfig = {
        id: {
            name: 'id',
            labelText: 'id',
        },
        general_instructions: {
            name: 'general_instructions',
            labelText: t('generalInstructions')
        },
        written_exam_instructions: {
            name: 'written_exam_instructions',
            labelText: t('writtenExamInstructions'),
        },
        oral_exam_instructions: {
            name: 'oral_exam_instructions',
            labelText: t('oralExamInstructions'),
        },
        thanks_page: {
            name: 'thanks_page',
            labelText: t('thanksPage'),
        },
        partner_page: {
            name: 'partner_page',
            labelText: t('partnerPage'),
        },
    };

    return (
        <Wrapper>
            <FormWrapper
                appElement={appElement}
                initialValues={product}
                handleSubmit={handleSubmit}
                editable={editableConfig}
            >
                <RenderEditForm handleSubmit={handleSubmit} handleEdit={handleEdit}/>
            </FormWrapper>
        </Wrapper>
    );
}

function RenderEditForm({ editable, values, handleSubmit, handleEdit }) {
    const {t} = useTranslation();
    const [generalInstructions, setGeneralInstructions] = useState(values.general_instructions);
    const [writtenExamInstructions, setWrittenExamInstructions] = useState(values.written_exam_instructions);
    const [oralExamInstructions, setOralExamInstructions] = useState(values.oral_exam_instructions);
    const [thanksPage, setTanksPage] = useState(values.thanks_page);
    const [partnerPage, setPartnerPage] = useState(values.partner_page);

    const wysiwygConfiguration = getDefaultWysiwygConfig();

    return (
        <div>
            <Actions isEditMode={true} edit={handleEdit} save={() => handleSubmit(values)} />
            <input type="hidden" value={values.id} onChange={editable.onChange} {...editable.id} />
            <input type="hidden" value={values.general_instructions = generalInstructions} onChange={editable.onChange} {...editable.general_instructions} />
            <input type="hidden" value={values.written_exam_instructions = writtenExamInstructions} onChange={editable.onChange} {...editable.written_exam_instructions} />
            <input type="hidden" value={values.oral_exam_instructions = oralExamInstructions} onChange={editable.onChange} {...editable.oral_exam_instructions} />
            <input type="hidden" value={values.thanks_page = thanksPage} onChange={editable.onChange} {...editable.thanks_page} />
            <input type="hidden" value={values.partner_page = partnerPage} onChange={editable.onChange} {...editable.partner_page} />
            <h3>{ t('generalInstructions') }</h3>
            <Wysiwyg
                data={generalInstructions}
                config={wysiwygConfiguration}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    setGeneralInstructions(data);
                }}
            />
            <h3>{ t('writtenExamInstructions') }</h3>
            <Wysiwyg
                data={writtenExamInstructions}
                config={wysiwygConfiguration}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    setWrittenExamInstructions(data);
                }}
            />
            <h3>{ t('oralExamInstructions') }</h3>
            <Wysiwyg
                data={oralExamInstructions}
                config={wysiwygConfiguration}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    setOralExamInstructions(data);
                }}
            />
            <h3>{ t('thanksPage') }</h3>
            <Wysiwyg
                data={thanksPage}
                config={wysiwygConfiguration}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    setTanksPage(data);
                }}
            />
            <h3>{ t('partnerPage') }</h3>
            <Wysiwyg
                data={partnerPage}
                config={wysiwygConfiguration}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    setPartnerPage(data);
                }}
            />
        </div>
    );
}
