import React, {useEffect} from 'react';
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {useQuery} from 'react-apollo';
import {LearningArea, Product, SubArea, SubArea as SubAreaApi} from "@appucations/common-api";
import SubAreaListing from "../../../../components/learningArea/panel/subareaItem/list";

function SubAreaList({ product, history, ...otherProps }) {
    const learningAreaId = otherProps.match.params.learningAreaId || null;
    const subAreaId = otherProps.match.params.subAreaId || null;
    const { refetch: refetchProduct, loading: productIsLoading } = useQuery(Product.getQuery(), {variables: {id: product.id}});
    const { data: subAreaData, refetch: refetchSubArea, loading: subAreaIsLoading } = useQuery(SubArea.getQuery(), {variables: {id: subAreaId}, skip: !subAreaId});
    const { data: subAreasData, refetch: refetchSubAreas, loading: subAreasAreLoading } = useQuery(SubAreaApi.getAllByLearningAreaIdAndSubAreaIdQuery(), { variables: { learning_area_id: learningAreaId, sub_area_id: subAreaId }});
    const subAreas = subAreasData ? subAreasData.subAreas : [];

    const { loading, data, refetch } = useQuery(
        LearningArea.getQuery(),
        {
            variables: { id: learningAreaId },
        });

    useEffect(() => {
        refetchProduct({variables: {id: product.id}});
        refetchSubAreas({ variables: { learning_area_id: learningAreaId, sub_area_id: subAreaId, skip: !subAreaId } });
        if (subAreaId) {
            refetchSubArea({variables: {id: subAreaId, skip: !subAreaId}});
        }
    });

    if (productIsLoading || subAreaIsLoading || subAreasAreLoading) {
        return <div>Loading</div>;
    }

    const subArea = subAreaData ? subAreaData.subArea : null;

    function goToAddSubArea() {
        if (!subAreaId) {
            history.push(`/learning/create/${learningAreaId}`);
        } else {
            history.push(`/learning/create/${learningAreaId}/${subAreaId}`);
        }
    }

    function goToAddLearningContent() {
        history.push(`/learning-content/create/${learningAreaId}/${subAreaId}`);
    }

    function buttonClicked() {
        history.push(`/learning/${learningAreaId}`);
    }

    return (
        <>
            <>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a onClick={buttonClicked}>{data && data.learningArea.headline && data.learningArea.headline.replace(/<[^>]*>?/gm, '').replaceAll('&shy;', '').replaceAll('&nbsp;','')}</a></li>
                        <li className="breadcrumb-item">{subAreaData && subAreaData.subArea.headline.replace(/<[^>]*>?/gm, '').replaceAll('&shy;', '').replaceAll('&nbsp;','')}</li>
                    </ol>
                </nav>
            </>

        <SubAreaListing
            history={history}
            learningAreaId={learningAreaId}
            subArea={subArea}
            subAreas={subAreas}
            linkToAddSubArea={goToAddSubArea}
            linkToAddLearningContent={goToAddLearningContent}
        />
        </>
    )
}

function withRedux({ product }) {
    return { product }
}

export default  connect(withRedux, null)(withRouter(SubAreaList));
