export function getEmptyQuestion(firstLearningAreaId = null) {
    return {
        key: new Date().getTime(),
        question: '',
        points: 1,
        answers: [
            getEmptyAnswer(),
        ],
        learningArea: {
            id: firstLearningAreaId,
        },
        type: 0,
        is_exercisable: false,
    };
}

export function getEmptyAnswer(order = 1) {
    return {
        key: 'answer-' + new Date().getTime(),  
        order,
        text: '',
        is_correct: false,
    }
}
