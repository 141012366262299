import React from 'react';
import {useParams, withRouter} from "react-router";
import {connect} from "react-redux";
import {useMutation, useQuery} from "@apollo/react-hooks";
// @TODO: this should use the dist files instead of src; good enough for now, but needs cleanup later on
import {LearningArea, LearningContent as LearningContentApi, Product, SubArea} from "@appucations/common-api";
import CreateLearningContentComponent from "../../../../../components/learningArea/content/create";

export function CreateLearningContent({ appElement, product, history }) {
    const { subAreaId, learningAreaId } = useParams();
    const { loading: productIsLoading } = useQuery(Product.getQuery(), {variables: {id: product.id}});
    const [createLearningContent] = useMutation(LearningContentApi.createMutation());
    const { loading, data: learningAreaData, refetch } = useQuery(LearningArea.getQuery(), {
        variables: { id: learningAreaId }, skip: !learningAreaId,
    });
    const {
        loading: loadingSubArea,
        data: dataSubArea,
        refetch: refetchSubArea,
    } = useQuery(SubArea.getQuery(), {
        variables: { id: subAreaId },
        skip: !subAreaId,
    });
    if (productIsLoading) {
        return <div>Loading</div>;
    }

    function handleCreate(data) {
        const { sub_area_id, headline, wysiwyg, reading_time } = data;
        let time = Math.floor(reading_time * 60);
        console.log('reading_time:', time, 'type: ', typeof time)
        createLearningContent({ variables: { sub_area_id, headline, wysiwyg, learning_area_id: learningAreaId, reading_time: time } }).then(close);
    }

    function close() {
        history.push(`/learning/${learningAreaId}/${subAreaId}`);
    }

    function buttonClicked() {
        history.push(`/learning/${learningAreaId}`);
    }

    return (
        <>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a onClick={buttonClicked}>{learningAreaData && learningAreaData.learningArea.headline && learningAreaData.learningArea.headline.replace(/<[^>]*>?/gm, '').replaceAll('&shy;', '').replaceAll('&nbsp;','')}</a></li>
                    <li className="breadcrumb-item">{dataSubArea && dataSubArea.subArea.headline.replace(/<[^>]*>?/gm, '').replaceAll('&shy;', '').replaceAll('&nbsp;','')}</li>
                </ol>
            </nav>
        <CreateLearningContentComponent
            appElement={appElement}
            history={history}
            subAreaId={subAreaId}
            handleEdit={close}
            handleCreate={handleCreate}
        />
        </>
    );
}

function withRedux({ appElement, product }) {
    return { appElement, product }
}

export default connect(withRedux, null)(withRouter(CreateLearningContent));
