import React from 'react';
import {useParams, withRouter} from "react-router";
import {connect} from "react-redux";
import {useQuery} from 'react-apollo';
import {Setting} from "@appucations/common-api";
import {useMutation} from "@apollo/react-hooks";
import UpdateSettingComponent from "../../../components/setting/update";

function UpdateSetting({ appElement, product, history }) {
    const {settingId} = useParams();
    const {loading, data} = useQuery(Setting.getQuery(), {variables: {id: settingId}})
    const [deleteSetting] = useMutation(Setting.deleteMutation());
    const [updateSetting] = useMutation(Setting.updateMutation());

    if (loading) {
        return (
            <p>Loading ...</p>
        );
    }

    function handleUpdate(data) {
        let { id, name, description, value, type, input_type } = data;

        updateSetting({
            variables: {
                id,
                name,
                description,
                value: transformValue(value, input_type),
                type,
                input_type,
                product_id: product.id,
            }
        }).then(close);
    }

    function handleDelete(id) {
        deleteSetting({
            variables: {id}
        }).then(close);
    }

    function close() {
        history.push(`/settings`);
    }

    function transformValue(value, inputType) {
        if (inputType === 'checkbox') {
            return value ? 'true' : 'false';
        }

        return value;
    }

    function reverseTransformValue(value, inputType) {
        if (inputType === 'checkbox') {
            return value === 'true';
        }

        return value;
    }

    let setting = {...data.setting};
    setting.value = reverseTransformValue(setting.value, setting.input_type);

    return (
        <UpdateSettingComponent
            appElement={appElement}
            history={history}
            setting={setting}
            handleEdit={close}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
        />
    )
}

function withRedux({ appElement, product }) {
    return { appElement, product }
}

export default connect(withRedux, null)(withRouter(UpdateSetting));
